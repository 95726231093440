import { applyDiscount } from '@/lib/helpers/prices'
import { usdFormatter } from './pdp'
import { getProductDiscountByGroup } from '@/lib/product-helper'

export const getGroupDiscountRule = (customerGroup, type) => {
  if (customerGroup && customerGroup?.discount_rules && customerGroup?.discount_rules.length > 0) {
    return customerGroup?.discount_rules?.find((rule) => rule?.type === type) || null
  }

  return null
}

export const getGroupDiscountedPrice = (customerGroup, price) => {
  const discount = customerGroup?.discount ? customerGroup?.discount?.amount : null

  return applyDiscount(price, discount)
}

export const getMaxDiscountedPrice = (customerGroup, price, customFields) => {
  const productDiscount = getProductDiscountByGroup(customFields, customerGroup?.name)

  // if no product discount custom field is found return group discount
  if (!productDiscount) {
    return getGroupDiscountedPrice(customerGroup, price)
  }

  const groupDiscountAmount = customerGroup?.discount?.amount ?? 0

  // compare the group discount with the product discount and use the lower value
  return productDiscount > groupDiscountAmount
    ? getGroupDiscountedPrice(customerGroup, price)
    : applyDiscount(price, productDiscount)
}

export const proUSerDiscountPricePLP = (regularPrice, salePrice, group, customFields) => {
  let discountPrice = getMaxDiscountedPrice(group, regularPrice, customFields)

  if (+salePrice !== 0 && salePrice !== null) {
    if (+discountPrice > +salePrice) {
      return `${salePrice ? usdFormatter.format(salePrice) : '$00.00'}`
    } else {
      return `${discountPrice ? usdFormatter.format(discountPrice) : '$00.00'}`
    }
  } else {
    return `${discountPrice ? usdFormatter.format(discountPrice) : '$00.00'}`
  }
}
