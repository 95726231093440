import pkceChallenge from 'pkce-challenge'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import qs from 'qs'

export const authorizeUrlRequest = async () => {
  try {
    const challenge = pkceChallenge()

    const params = new URLSearchParams({
      client_id: process.env.NEXT_PUBLIC_PING_LOGIN_CLIENT,
      response_type: 'code',
      //redirect_uri: process.env.NEXT_PUBLIC_PING_REDIRECT_URI,  enable when needed
      response_mode: 'pi.flow',
      scope: 'openid',
      code_challenge: challenge.code_challenge,
      code_challenge_method: 'S256',
    }).toString()

    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/as/authorization.oauth2?${params}`

    const response = await axios.get(url, { withCredentials: true })

    return {
      flowId: response?.data?.id,
      verifier: challenge.code_verifier,
      status: response?.data?.status,
    }
  } catch (error) {
    console.log('authorizeUrlRequest error', error)
    throw error
  }
}

export const initiateAccountRecovery = async (flowId) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/pf-ws/authn/flows/${flowId}`

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/vnd.pingidentity.initiateAccountRecovery+json',
          'X-XSRF-Header': 'PingFederate',
        },
        withCredentials: true,
      }
    )

    return response?.data
  } catch (error) {
    console.log('initiateAccountRecovery error:', error)
    throw error
  }
}

export const submitRequiredUsername = async (flowId, email) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/pf-ws/authn/flows/${flowId}`

    const data = {
      username: email,
    }

    const response = await axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/vnd.pingidentity.checkAccountRecoveryUsername+json',
        'X-XSRF-Header': 'PingFederate',
      },
      withCredentials: true,
    })

    return response?.data
  } catch (error) {
    console.log('submitRequiredUsername error:', error)
    throw error
  }
}

export const submitUsername = async (flowId, email) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/pf-ws/authn/flows/${flowId}`

    const data = {
      identifier: email,
    }

    const response = await axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/vnd.pingidentity.submitIdentifier+json',
        'X-XSRF-Header': 'PingFederate',
      },
      withCredentials: true,
    })

    return response
  } catch (error) {
    console.log('submitUsername error', error)
    throw error
  }
}

export const searchPingUser = async (email) => {
  try {
    const url = `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/ping/user/search`

    const response = await axios.post(url, { email })

    return response
  } catch (error) {
    console.log('searchPingUser error', error)
    throw error
  }
}

export const validateGlsAccount = async (email) => {
  try {
    const url = `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/ping/gls/user/search`
    const accountUnique = `${process.env.NEXT_PUBLIC_PING_ACCOUNT_SCOPE}${email.toLowerCase()}`
    const accountUniquesha512 = CryptoJS.SHA512(accountUnique)
    const accountEmailHash = accountUniquesha512.toString(CryptoJS.enc.Hex)

    const data = {
      username: accountEmailHash,
    }

    const response = await axios.post(url, data)

    return response.data
  } catch (error) {
    console.log('validateGlsAccount error:', error)
    throw error
  }
}

export const submitCredentials = async (flowId, credentials) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/pf-ws/authn/flows/${flowId}`

    const response = await axios.post(url, JSON.stringify(credentials), {
      headers: {
        'Content-Type': 'application/vnd.pingidentity.checkUsernamePassword+json',
        'X-XSRF-Header': 'PingFederate',
      },
      withCredentials: true,
    })

    return response
  } catch (error) {
    console.log('submitCredentials error:', error)
    throw error
  }
}

export const requestAccessToken = async (authCode, verifier) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/as/token.oauth2`

    const payload = {
      grant_type: 'authorization_code',
      code: authCode,
      //redirect_uri: process.env.NEXT_PUBLIC_PING_REDIRECT_URI, enable when needed
      code_verifier: verifier,
      client_id: process.env.NEXT_PUBLIC_PING_LOGIN_CLIENT,
    }

    const response = await axios.post(url, qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-XSRF-Header': 'PingFederate',
      },
      withCredentials: true,
    })

    return response
  } catch (error) {
    console.log('requestAccessToken error:', error)
    throw error
  }
}

export const verfiyPingAccount = async (hash) => {
  try {
    const url = `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/ping/user/verify`

    const response = await axios.post(url, {
      hash: hash,
    })

    return response
  } catch (error) {
    console.log('verifyAccount error:', error)
    throw error
  }
}

export const resendVerificationEmail = async (email) => {
  try {
    const url = `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/ping/user/re-verify`

    const response = await axios.post(url, {
      email: email,
    })

    return response
  } catch (error) {
    console.log('resendVerificationEmail error:', error)
    throw error
  }
}

export const logoutRevokeToken = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/idp/startSLO.ping`

    const response = await axios.get(url, {
      withCredentials: true,
    })
    return response
  } catch (error) {
    console.log('logoutRevalidate error:', error)
    throw error
  }
}
