export const applyDiscount = (price, discount) => {

  if (price && discount) {
    const discountedPrice = Number(price) - (Number(price) * Number(discount)) / 100

    return discountedPrice.toFixed(2)
  }

  return null
}
