const container = require('./styles/container')
const buttons = require('./styles/buttons')
const input = require('./styles/input')
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  option: {
    whitelist: ['overflow-hidden'],
  },
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        sm: '2rem',
        lg: '4rem',
        xl: '5rem',
        '2xl': '6rem',
      },
    },
    fontFamily: {
      primary: ['Theinhardt Pan', 'sans-serif'],
      secondary: ['Nantes', 'sans-serif'],
      tertiary: ['Source Code Pro', 'sans-serif'],
    },
    screens: {
      '2xs': '280px',
      xs: '375px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      colors: {
        black: '#000000',
        white: '#FFFFFF',
        primary: '#DD5F13',
        primaryHover: '#C3530F',
        error: '#EF4444',
        secondaryGreen: '#BDCF9B',
        mutedBlue: '#97A3AE',
        mutedBlueHover: '#BBC9D6',
        darkBlue: '#4b5157',
        SandyGray: '#81746C',
        secondaryLightGray: '#CAC8C8',
        secondarySandyGray: '#847770',
        secondaryDarkGray: '#4C4442',
        gray: {
          50: '#F7F7F7',
          100: '#F0F0EF',
          200: '#E7E5E4',
          300: '#D6D3D1',
          400: '#A8A29E',
          500: '#78716C',
          600: '#57534E',
          700: '#44403C',
          800: '#292524',
          900: '#1C1917',
        },
        lime: '#DEFF00',
        lightBlue: '#00AAFF',
        secondary: '#7c99a3',
        secondaryLight: '#7c99a3',
        atcPrimary: '#00B5EC',
        atcPrimaryHover: '#008DB8',
        productCardBg: '#F7F7F7',
        lightGreen: '#BDCF9B',
        reviewDarkBg: '#4C4442',
        reviewTextColor: '#CAC8C8',
        adminBlack: '#374151',
        adminGray: '#F7F9FC',
        red: '#EF4444',
        requiredError: '#C10000',
        warning: '#C04800',
        greenConfirmation: '#427D00',
        inactiveGray: '#6F7682',
        outlineGray: '#AFB3B9',
        disabledGray: '#E5E5E5',
        disabledText: '#707684',
        darkWarmGray: '#4C4442',
        pdpGreen: '#49680D',
        pdpBgGray: '#f9f9f9',
        sliderGray: '#F0F0F0',
        dividerGray: '#D9D9D9',
        grayNonSelected: '#77706B',
      },
      inset: {
        '1/2': '50%',
        '-1/2': '-50%',
        '2/4': '50%',
        '-2/4': '-50%',
      },
      lineHeight: {
        none: '1',
        tight: '1.2',
        snug: '1.3',
        normal: '1.5',
        relaxed: '1.57',
        loose: '1.66',
      },
      fontSize: {
        '2xs': '0.625rem', //10px
        xs: '0.75rem', //12px
        sm: '0.875rem', //14px
        base: '1rem', //16px
        smd: '1.125rem', //18px
        md: '1.25rem', //20px
        lg: '1.5rem', //24px
        xl: '2rem', //32px
        '2xl': '2.25rem', //36px
        '3xl': '3rem', //48px
        '4xl': '3.75rem', //60px
        '5xl': '4.5rem', //72px
      },
      screen: {
        '2xl': '1536px',
      },
      spacing: {
        1.5: '0.375rem',
        2.5: '0.625rem',
        7: '1.875rem',
        14: '3.5rem',
        15: '3.75rem',
        29: '7.5rem',
        60: '15rem',
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            h1: {
              color: theme('colors.black'),
              fontSize: theme('fontSize.4xl'),
              textTransform: 'uppercase',
              textAlign: 'center',
              fontWeight: '700',
              lineHeight: '66px',
              letterSpacing: '6%',
            },
            h2: {
              textTransform: 'uppercase',
              textAlign: 'center',
            },
            h3: {
              //section title
              color: theme('colors.black'),
              fontSize: '28px',
              textTransform: 'uppercase',
              textAlign: 'center',
              fontWeight: '700',
              lineHeight: '36px',
              letterSpacing: '0.08em',
              marginTop: 0,
              // '&:hover': {
              //   color: '#2c5282',
            },
            h4: {
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: '20px',
              lineHeight: '24px',
              // textAlign: 'center',
              // fontWeight: '700',
              // letterSpacing: '0.08em',
              // marginTop: 0,
            },
            h5: {
              //section subtitle
              color: theme('colors.secondaryDarkGray'),
              textTransform: 'uppercase',
              textAlign: 'center',
              marginBottom: '8px',
              fontWeight: '700',
              fontSize: theme('fontSize.xs'), //10px
              letterSpacing: '0.1em',
              lineHeight: '125%',
            },
            h6: {
              color: theme('colors.secondaryDarkGray'),
              fontSize: '28px',
              letterSpacing: '0.08em',
            },
            p: {
              color: '#0C1319',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '18px',
              letterSpacing: '0.02em',
              textAlign: 'left',
            },
            img: {
              margin: '0 auto',
              maxWidth: '100%',
            },
            tr: {
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            },
            a: {
              letterSpacing: '0.1em',
              textTransform: 'uppercase',
              fontWeight: 700,
              fontSize: 'inherit',
              borderBottom: '2px solid #DD5F13',
              width: 'fit-content',
              display: 'flex',
              paddingBottom: '4px',
              textDecoration: 'none',
            },
          },
        },
        // 'xl': {
        //   css: {
        //     h3: {
        //       color: '#3182ce',
        //       '&:hover': {
        //         color: '#2c5282',
        //       },
        //       textAlign: 'center'
        //     }
        //   }
        // }
      }),
    },
    // listStyleType: {
    //   none: 'none',
    //   disc: 'disc',
    //   decimal: 'decimal',
    // }
  },
  corePlugins: {
    container: false,
  },
  plugins: [
    require('@tailwindcss/typography'),
    container(),
    buttons(),
    input(),
    plugin(function ({ addBase, theme }) {
      addBase({
        'ul li h5': {
          textAlign: 'left !important',
        },
      })
    }),
  ],
  // variants: {
  //   display: ['responsive', 'hover', 'focus', 'group-hover'],
  //   borderWidth: ['responsive', 'hover', 'focus', 'group-hover'],
  //   borderColor: ['responsive', 'hover', 'focus', 'group-hover'],
  //   boxShadow: ['responsive', 'hover', 'focus', 'group-hover'],
  //   textColor: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
  //   visibility: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
  // }
}
