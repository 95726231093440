import axios from 'axios'
import https from 'https'

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.NEXT_PUBLIC_COMMERCE_URL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  }

  // Create instance
  const instance = axios.create(defaultOptions)

  instance.interceptors.request.use(
    async function (config) {
      let idToken = null
      const isClient = typeof window !== 'undefined'
      if (isClient) {
        const usersInfo = JSON.parse(localStorage.getItem('persist:gore-na'))
        //console.debug('usersInfo', usersInfo)
        if (usersInfo) {
          idToken = JSON.parse(usersInfo['userReducer'])
        }

        // if (idToken && String(idToken.access_token).length < 12) {
        //   let tokenData = JSON.parse(localStorage.getItem('gore:token') || '{}')
        //   if (tokenData.access_token) {
        //     idToken = tokenData
        //   }
        // }
      }
      config.headers.common['Authorization'] = idToken ? `Bearer ${idToken.access_token}` : ''
      // config.headers['access-control-allow-origin'] = `*`
      // config.headers['Access-Control-Allow-Origin'] = `*`
      // config.headers['Content-Type'] = `application/x-www-form-urlencoded`
      // config.headers['origin'] = `http://localhost:3000`
      config['withCredentials'] = true
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      //console.debug('Axios onResponseSuccess', response)
      return response.data
    },
    (error) => {
      //console.debug('Axios onResponseError', error.response)
      if (error && error.response && error.response.status === 401) {
        //store.dispatch(onUnauthorizedToken())
      }
      return Promise.reject(error)
    }
  )

  return instance
}

const CartClient = fetchClient()

export { CartClient }
