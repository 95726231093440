import axios from 'axios'

export const getProductSizeChart = (customFields, sizeCharts) => {
  try {
    const sizeChartId = customFields?.find(
      (item) => item?.name?.toLowerCase() == 'size chart'
    )?.value

    if (!sizeChartId || sizeCharts.length == 0) {
      return null
    }

    const sizeChart = sizeCharts.find((item) => item?.sizeChartId == sizeChartId)

    return sizeChart ?? null
  } catch (error) {
    console.log('getProductSizeChart error', error)
  }
}

export const getSizeChartById = async (id) => {
  try {
    const { data } = await axios.post(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
      }/api/contentful/module/size-chart`,
      { sizeChartId: id }
    )

    return data
  } catch (error) {
    console.log('getSizeChartById error', error)
  }
}
