import { default as categoryNav } from '@/lib/bigcommerce/data/category-nav.json'
import { default as categoryBatch } from '@/lib/bigcommerce/data/category-batch.json'
import { default as brandData } from '@/lib/bigcommerce/data/brands.json'
import { buildSwatchesUrl } from './helpers/pdp'
import { isJsonString } from './helpers/strings'
import { NextImage } from '@/components/Image/NextImage'
import { isEmpty } from './helpers/objects'
import swatchOrder from '../public/swatch-order.json'
import { useRouter } from 'next/router'
import { getBackgroundFilter } from './searchspring'
import Cookies from 'js-cookie'
import { split } from 'lodash'
import { getColorName, displayWithCurrentDate, isDateValid } from './helper'
import { getCustomBadgeData } from '@/lib/contentful/badge'

export const getRelatedProductInfo = async (id) => {
  const related_prod = new Array()
  const data = await client.products.get({ id: id }).then(async (data) => {
    const image_url = await client.productImages.list({ product_id: data.id }).then((image) => {
      if (image?.length) {
        return image[0]?.url_standard
      }
      return 'https://dummyimage.com/600x400/000/fff'
    })
    data.image_url = image_url
    related_prod.push({
      id: data.id,
      name: data.name,
      category: getCategories(data.categories),
      image: data.image_url,
      url: data.custom_url.url,
      price: data.calculated_price,
    })
    return data
  })
  return related_prod
}

// gets the default image
export const getDefaultImage = (images) => {
  if (images.length > 0) {
    const primaryImage = images.find((image) => image.is_thumbnail)
    return [{ ...primaryImage, url: primaryImage.url_standard }]
  }

  return {
    node: {
      urlOriginal: 'https://dummyimage.com/600x400/000/fff',
      altText: '',
      isDefault: true,
    },
  }
}

// get variant images
export const getVariantImages = (images) => {
  let variantImages = []

  if (images?.length > 0) {
    let defaultImages = images?.filter((image) => {
      let description = image?.description ? JSON.parse(image?.description) : ''

      if (description.number == '1' && !description.isModel) {
        return image
      }
    })

    if (defaultImages?.length > 0) {
      defaultImages.forEach((image) => {
        let description = image?.description ? JSON.parse(image?.description) : ''

        variantImages.push({
          color: description.colorName,
          ...image,
        })
      })
    }

    return variantImages
  }

  return variantImages
}

// gets all images except the default
export const getOtherImages = (images) => {
  if (images?.length > 0) {
    return images
      .filter((image) => !image?.is_thumbnail)
      .map((image) => ({ ...image, url: image?.url_standard }))
  }

  return [
    {
      node: {
        urlOriginal: 'https://dummyimage.com/600x400/000/fff',
        altText: '',
        isDefault: false,
      },
    },
  ]
}

// get the product sale price
export const getSalePrice = (prices) => {
  return prices?.salePrice != null
    ? prices?.salePrice?.value.toFixed(2)
    : prices?.price?.value.toFixed(2)
}

// get the product retail price
export const getRetailPrice = (prices) => {
  return prices?.retailPrice != null
    ? prices?.retailPrice?.value.toFixed(2)
    : prices?.price?.value.toFixed(2)
}

// get default price if not base price is set //unused
export const getDefaultPrice = (variants) => {
  let defaultPrice = null

  let salePrice = 0

  if (variants?.length <= 0) {
    return defaultPrice
  }

  variants?.forEach((variant, index) => {
    if (index == '0') {
      defaultPrice = variant?.price
    }

    if (variant?.salePrice > salePrice) {
      defaultPrice = variant?.price
    }
  })

  return defaultPrice
}

export const onSale = (prices) => {
  return prices?.salePrice != null ? true : false
}

export const getDiscount = (retailPrice, salePrice) => {
  if (salePrice != null && salePrice != '0') {
    const discount = 100-((salePrice/retailPrice)*100);

    return parseInt(Math.floor(discount).toFixed(0))
  }
  return 0
}

export const getDiscountedPrice = (regularPrice, discount) => {
  const discountedPrice =
    parseInt(regularPrice) - (parseInt(regularPrice) * parseInt(discount)) / 100

  return discountedPrice.toFixed(2)
}

export const getCategories = (ids) => {
  let catArr = new Array()
  ids?.map((id) => {
    categoryNav?.data?.map((cat) => {
      if (cat?.name == 'Activity') {
        cat?.children?.map((c) => {
          if (c?.id == id) {
            catArr?.push(c)
          }
        })
      }
    })
  })
  return catArr
}

export const getCustomFieldValue = (data, name) => {
  const field_value = data?.filter((d) => d?.name == name)
  return field_value
}

export const filterColorOptions = (colorOptions, variants) => {
  let variantColors = []

  variants?.forEach((variant) => {
    let color = variant?.option_values?.find((option) => option?.option_display_name === 'Color')

    if (!isEmpty(color) && variant.purchasing_disabled === false) {
      if (!variantColors.includes(color?.label)) {
        variantColors.push(color?.label)
      }
    }
  })

  return colorOptions?.option_values.filter((option) =>
    variantColors?.find((variantColor) => variantColor === option?.label)
  )
}

const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const areEqual = (array1, array2) => {
  const intersection = array1.filter((element) => {
    return array2.includes(element)
  })
  if (intersection.length > 0) {
    return true
  }
  return false
}
// Generic helper function that can be used for the three operations:
const operation = (list1, list2, isUnion = false) =>
  list1.filter(
    (
      (set) => (a) =>
        isUnion === set.has(a.label)
    )(new Set(list2.map((b) => b.label)))
  )

// Following functions are to be used:
const inBoth = (list1, list2) => operation(list1, list2, true),
  inFirstOnly = operation,
  inSecondOnly = (list1, list2) => inFirstOnly(list2, list1)

const sortArray = (arr1, arr2) => {
  arr1 = arr1?.length > 0 && arr1.filter((item) => item != undefined)
  arr2 = arr2?.length > 0 && arr2.filter((item) => item != undefined)
  let keepRateOrder =
    arr1?.length > 0 &&
    arr1.slice().sort((a, b) => {
      return arr2.findIndex((p) => p.label === a.color) - arr2.findIndex((p) => p.label === b.color)
    })
  return keepRateOrder
}

// Custom comparison function
const compareSalePrice = (a, b) => {
  const salePriceA = a.sale_price
  const salePriceB = b.sale_price

  // Check for null values and move them to the end
  if (salePriceA === null && salePriceB !== null) {
    return 1
  }
  if (salePriceA !== null && salePriceB === null) {
    return -1
  }

  // Regular ascending order for non-null values
  return salePriceA - salePriceB
}

const sortSwatches = (product, categoryName, swatches, isSaleSort = false) => {
  const currentTime = Math.floor(new Date().getTime() / 1000)

  const router = window.location.pathname
  const params = router?.includes('?') ? router?.split('?')[0].split('/') : router?.split('/')
  params = params.filter((item) => item != '' && item != null && item != undefined)
  const bgFilter = getBackgroundFilter({ slug: params })
  let categoryNamesArray = bgFilter.includes('>') ? bgFilter.split('>') : [bgFilter]
  let swatchSortOrder = null
  if (categoryNamesArray[categoryNamesArray.length - 1] == 'Pursuit') {
    swatchSortOrder = swatchOrder.data.find((item) => item.category.includes('Big Game'))
  }
  if (categoryNamesArray[categoryNamesArray.length - 1] == 'Pattern') {
    swatchSortOrder = swatchOrder.data.find((item) => item.category.includes('Blaze Orange'))
  }
  if (swatchSortOrder == null) {
    swatchSortOrder = swatchOrder.data.find((item) => {
      let checkIfArrayIncludes = areEqual(item.category, categoryNamesArray)
      if (checkIfArrayIncludes) return item
    })
  }
  if (!swatchSortOrder) {
    swatchSortOrder = swatchOrder.data.find((item) => item.category.includes('Big Game'))
  }
  if (swatchSortOrder) {
    const sortListCount = Object.keys(swatchSortOrder.swatch)
    const newSwatchOrder = []
    for (let i = 0; i < sortListCount.length; i++) {
      const swatch = swatchSortOrder.swatch[i]
      const findSwatch = swatches.find((swat) => swat.label.toLowerCase() == swatch.toLowerCase())
      if (findSwatch) {
        newSwatchOrder.push(findSwatch)
      }
      if (swatches.length == newSwatchOrder.length) {
        break
      }
    }
    let onSaleColor = []
    let newArr = newSwatchOrder
    if (params && params.length > 0 && (params[0].toLowerCase() == 'sale' || isSaleSort)) {
      let variants = [...product.variantsData]
      variants = variants.sort(compareSalePrice)
      variants.map((item) => {
        if (item?.sale_price != null && item?.sale_price != 0) {
          const saleColor = item.option_values.find(
            (item) => item.option_display_name == 'Color'
          ).label
          if (onSaleColor.indexOf(saleColor) == -1) {
            onSaleColor.push(saleColor)
          }
        }
      })
      newSwatchOrder = newSwatchOrder.filter((item) => item != undefined)
      const foundColors = []
      if (onSaleColor.length > 0) {
        for (let i = 0; i < onSaleColor.length; i++) {
          let checkColor = newSwatchOrder.find(
            (item) => item.label.toLowerCase() == onSaleColor[i].toLowerCase()
          )
          if (checkColor) {
            foundColors.push(checkColor)
          }
          // const colorIndex = newSwatchOrder.findIndex(
          //   (item) => item.label.toLowerCase() == onSaleColor[i].toLowerCase()
          // )
          // if(colorIndex != i)
          //   newArr = arraymove(newArr, colorIndex, i)
        }
        let intersect = inSecondOnly(foundColors, newSwatchOrder)
        newArr = foundColors.concat(intersect)
      }
    }
    newArr = newArr.filter((item) => item != undefined)
    const notFoundEle = []
    swatches.map((item) => {
      const foundele = newArr.find((ele) => ele.label.toLowerCase() == item.label.toLowerCase())
      if (!foundele) {
        notFoundEle.push(item)
      }
    })
    newArr = newArr.concat(notFoundEle)
    if (currentTime <= 1700981940) {
      if (product.name == 'Icon Pullover Hoody') {
        const newArray = [
          newArr.find((item) => item.label === 'Sitka Black Blaze Orange'),
          ...newArr.filter((item) => item.label !== 'Sitka Black Blaze Orange'),
        ]
        return newArray
      }
    }
    return newArr
  }
  if (currentTime <= 1700981940) {
    if (product.name == 'Icon Pullover Hoody') {
      const newArray = [
        swatches.find((item) => item.label === 'Sitka Black Blaze Orange'),
        ...swatches.filter((item) => item.label !== 'Sitka Black Blaze Orange'),
      ]
      return newArray
    }
  }
  return swatches
}

export const getFirstImagePlp = (product, bgFilterParam = '', isSaleSort = false) => {
  const colorOptions = product?.options?.find((option) => option?.display_name == 'Color')
  let filteredColorOptions = filterColorOptions(colorOptions, product?.variantsData) || []
  filteredColorOptions =
    sortSwatches(product, bgFilterParam, filteredColorOptions, isSaleSort) || []
  return filteredColorOptions.length > 0 ? filteredColorOptions[0].label : ''
}

export const getSortedVariants = (product, bgFilterParam = '', isSaleSort = false) => {
  const colorOptions = product?.options?.find((option) => option?.display_name == 'Color')
  let filteredColorOptions = filterColorOptions(colorOptions, product?.variantsData)
  filteredColorOptions = sortSwatches(product, bgFilterParam, filteredColorOptions, isSaleSort)
  let variantCollect = sortArray(product?.variantCollection, filteredColorOptions)
  return variantCollect
}

export const applySortingRule = (product, sortRule = null) => {
  try {
    let sortedVariants = product?.variantCollection
    if (sortRule) {
      sortRule = JSON.parse(sortRule)
      const firstSortRule = sortRule ? Object.keys(sortRule)[0] : null
      if (
        firstSortRule &&
        firstSortRule == 'lowtohighprice' &&
        sortRule['lowtohighprice'] == 'true'
      ) {
        sortedVariants = [...product.variantCollection].sort((a, b) => a.price - b.price)
      } else if (
        firstSortRule &&
        firstSortRule == 'colororder' &&
        sortRule['colororder'] != '' &&
        sortRule['colororder'].split('|').length > 0
      ) {
        const sortOrder = sortRule['colororder'].split('|')
        let initialVariantCollection = [...product.variantCollection]
        for (let i = 0; i < initialVariantCollection.length; i++) {
          let colorCode = initialVariantCollection[i]['sizes'][0]['sku'].split('-')[1]
          initialVariantCollection[i]['colorCode'] = colorCode
        }
        sortedVariants = initialVariantCollection.sort((a, b) => {
          let indexA = sortOrder.indexOf(a.colorCode)
          let indexB = sortOrder.indexOf(b.colorCode)
          if (indexA === -1 && indexB === -1) {
            return 0
          } else if (indexA === -1) {
            return 1
          } else if (indexB === -1) {
            return -1
          }
          return indexA - indexB
        })
      }
    } else {
      sortedVariants = product.variantCollection
    }
    return sortedVariants
  } catch (error) {
    //console.log('applySortingRule error')
    return product?.variantCollection
  }
}

export const getSelectedVariant = (colorPrices, sortedVariants, filterSeqSorting = null) => {
  const selectedVariant = null
  if (sortedVariants && sortedVariants.length > 0) {
    for (let i = 0; i < sortedVariants.length; i++) {
      let findInColorPrices = colorPrices.find((color) => color.color == sortedVariants[i].color)
      if (findInColorPrices) {
        selectedVariant = {
          id: sortedVariants[i].sizes[0]?.id,
          color: sortedVariants[i].color,
          sizes: sortedVariants[i].sizes,
        }
        break
      }
    }
  }
  if (filterSeqSorting != null) {
    let colorFilterOption = sortedVariants.find(
      (item) => item.color.toLowerCase() == filterSeqSorting.toLowerCase()
    )
    if (colorFilterOption) {
      selectedVariant = {
        id: colorFilterOption.sizes[0]?.id,
        color: colorFilterOption.color,
        sizes: colorFilterOption.sizes,
      }
    }
  }
  if (colorPrices.length == 0 && filterSeqSorting == null) {
    selectedVariant = {
      id: sortedVariants[0].sizes[0]?.id,
      color: sortedVariants[0].color,
      sizes: sortedVariants[0].sizes,
    }
  }
  return selectedVariant
}

export const displayColorOptions = (
  product,
  onClick,
  bgFilterParam = '',
  isSaleSort = false,
  sortingRule,
  availColors = null
) => {
  const colorOptions = product?.options?.find((option) => option?.display_name == 'Color')
  const colorBoxes = []

  if (isEmpty(colorOptions)) return <></>

  // OLD SORT LOGIC
  let filteredColorOptions = filterColorOptions(colorOptions, product?.variantsData)
  filteredColorOptions = sortSwatches(product, bgFilterParam, filteredColorOptions, isSaleSort)
  let variantCollect = sortArray(product?.variantCollection, filteredColorOptions)

  // NEW SORT LOGIC
  let sortedVariantsList = applySortingRule(product, sortingRule)
  if (availColors) {
    let matchingVariantList = sortedVariantsList.filter(
      (item) => item.color.toLowerCase() == availColors.toLowerCase()
    )
    let notMatchingVariantList = sortedVariantsList.filter(
      (item) => item.color.toLowerCase() != availColors.toLowerCase()
    )
    sortedVariantsList = [...matchingVariantList, ...notMatchingVariantList]
  }
  let isSelected = false
  if (sortedVariantsList?.length > 0) {
    sortedVariantsList?.forEach((options, index) => {
      let option = { ...options }
      option['label'] = option?.color

      if (product?.selectedVariant !== null) {
        if (product?.selectedVariant?.color === option?.label) {
          isSelected = true
          option['isSelected'] = isSelected
          // onClick(option?.label, product, option)
        }
      }
      let color = buildSwatchesUrl(option)

      if (product.availableColorsPlp && product.availableColorsPlp.length != 0) {
        if (product.availableColorsPlp.includes(option.label)) {
          if (!product?.selectedVariant?.vId && isSelected === false) {
            isSelected = true
            option['isSelected'] = isSelected
            // onClick(option?.label, product, option)
          }

          colorBoxes?.push(
            <div
              tabIndex="0"
              role="button"
              aria-label={`Select ${option?.label}`}
              title={option.label}
              key={`color-${index}`}
              className={`color-image-filter-wrap flex border border-2  cursor-pointer
                ${option?.isSelected ? 'selected-color border-black border-2' : ''}
              `}
              onClick={() => onClick(option?.label, product, option)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClick(option?.label, product, option)
                }
              }}
            >
              <div
                className="cursor-pointer color-image-filter-item"
                data-label={option?.label}
                data-type="color"
              >
                {color ? (
                  // <img src={color} alt={color} className="swatch-image" loading="lazy" width="100%" height="100%" data-lazy={color} />
                  // <Image src={color} loader={ImageLoaderBC} width={30} height={30} alt={'...'} />
                  <NextImage
                    src={color}
                    width={30}
                    height={30}
                    alt={'...'}
                    layout="intrinsic"
                    objectFit="cover"
                    objectPosition="center"
                    className="swatch-image-option"
                  />
                ) : (
                  option?.label
                )}
              </div>
            </div>
          )
        }
      } else {
        colorBoxes?.push(
          <div
            tabIndex="0"
            role="button"
            aria-label={`Select ${option?.label}`}
            title={option.label}
            key={`color-${index}`}
            className={`color-image-filter-wrap flex border border-2  cursor-pointer
              ${option?.isSelected ? 'selected-color border-black border-2' : ''}
            `}
            onClick={() => onClick(option?.label, product, option)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClick(option?.label, product, option)
              }
            }}
          >
            <div
              className="cursor-pointer color-image-filter-item"
              data-label={option?.label}
              data-type="color"
            >
              {color ? (
                // <img src={color} alt={color} className="swatch-image" loading="lazy" width="100%" height="100%" data-lazy={color} />
                // <Image src={color} loader={ImageLoaderBC} width={30} height={30} alt={'...'} />
                <NextImage
                  src={color}
                  width={30}
                  height={30}
                  alt={'...'}
                  layout="intrinsic"
                  objectFit="cover"
                  objectPosition="center"
                  className="swatch-image-option"
                />
              ) : (
                option?.label
              )}
            </div>
          </div>
        )
      }
    })

    return colorBoxes
  }

  return <></>
}

// display product colors based on product variant
export const displayVariantColors = (product, onClick) => {
  const variants = product?.variantCollection
  const variantColors = []

  const variantBoxes = []

  //get the color values
  variants.forEach((variant) => {
    let count = 0
    for (let item in variant?.sizes) {
      if (variant?.sizes[item]?.inventory > 0) {
        count = count + 1
        break
      }
    }
    if (count > 0) {
      variantColors.push(variant?.color)
    }
  })
  // check if variant colors are available
  if (variantColors.length > 0) {
    variantColors.forEach((variantColor, index) => {
      let isSelected = false

      if (product?.selectedVariant !== null) {
        if (product?.selectedVariant?.color === variantColor) {
          isSelected = true
        }
      }

      if (variantColor?.includes('/')) {
        let color = variantColor?.split('/')

        // let primaryColor = filterCode.data[capitalize(color[0].replace(/ /g,''))];

        // let secondaryColor = filterCode.data[capitalize(color[1].replace(/ /g,''))];

        variantBoxes?.push(
          <div
            key={`color-${index}`}
            className={`color-image-filter-wrap flex border border-gray-200 cursor-pointer mr-2 mb-2 ${
              isSelected ? 'selected-color' : ''
            }`}
            onClick={() => onClick(variantColor, product)}
          >
            <div
              className="cursor-pointer color-image-filter-item"
              data-label={variantColor}
              data-type="color"
            >
              <div className="relative overflow-hidden">
                {/* <img src={`/images/swatch/${primaryColor}h.png`} alt={primaryColor} className="swatch-image swatch-half-image"/>
                <img src={`/images/swatch/${secondaryColor}h.png`} alt={secondaryColor} className="swatch-image"/> */}
              </div>
            </div>
          </div>
        )
      } else {
        let splitVal = ''
        let joinData = ''
        let color = ''
        if (variantColor.includes(' ')) {
          splitVal = variantColor.split(' ')
          let i = 0
          splitVal.map((item) => {
            if (i != 0) {
              let nameCapitalized = item.charAt(0).toUpperCase() + item.slice(1)
              splitVal[i] = nameCapitalized
            } else if (i == 0) {
              let nameLowercase = item.charAt(0).toLowerCase() + item.slice(1)
              splitVal[i] = nameLowercase
            }
            i = i + 1
          })
          joinData = splitVal.join('')
        }
        // if(joinData != '') {
        //   color = filterCode.data[joinData]
        // }
        // else {
        //   color = filterCode.data[capitalize(variantColor.replace(/ /g,''))]
        // }
        variantBoxes.push(
          <div
            key={`color-${index}`}
            className={`color-image-filter-wrap flex border border-gray-200 cursor-pointer mr-2 mb-2 ${
              isSelected ? 'selected-color' : ''
            }`}
            onClick={() => onClick(variantColor, product)}
          >
            <div
              className="cursor-pointer color-image-filter-item"
              data-label={variantColor}
              data-type="color"
            >
              {/* <img
                src={`/images/swatch/${color}.png`}
                alt={color ? color : 'color'}
                className="swatch-image"
              /> */}
              <NextImage
                src={`/images/swatch/${color}.png`}
                alt={color ? color : 'color'}
                className="swatch-image"
                width={30}
                height={30}
                layout="intrinsic"
                objectFit="cover"
                objectPosition="center"
              />
            </div>
          </div>
        )
      }
    })

    return variantBoxes
  }

  return ''
}

export const displayPrice = (isAuthenticated, productData, customerGroup, price, disableATC) => {
  const customerGroupDiscountRules = getGroupDiscountRules(customerGroup)

  const product = {
    ...productData,
    id: productData.entityId,
  }
  const discountRule = getDefaultDiscountRule(customerGroupDiscountRules, product)

  if (disableATC) {
    return 'Out of Stock'
  }

  if (isAuthenticated) {
    if (getDiscount(price?.regularPrice, price?.salePrice) > formatDiscount(discountRule?.amount)) {
      return price?.calculatedPrice.toFixed(2)
    }

    if (customerGroup != null) {
      if (discountRule != null) {
        return getDiscountedPrice(price?.regularPrice, formatDiscount(discountRule?.amount))
      }
      return price?.calculatedPrice?.toFixed(2)
    }
  }

  return price?.calculatedPrice?.toFixed(2)
}

// set product image url. If no variant is selected the default image is displayed
export const setProductImageUrl = (product) => {
  let retunImg = ''
  if (product.selectedVariant) {
    let variantImage = product?.variantImages?.find(
      (image) => product?.selectedVariant?.color === image?.color
    )
    retunImg = isEmpty(variantImage) ? product?.selectedVariant?.image : variantImage?.url_standard
  } else if (product?.variantCollection && product?.variantCollection[0]) {
    retunImg = product?.variantCollection[0]['image']
  } else {
    retunImg = product?.image?.url
  }
  return retunImg
}

export const getColorVariants = (variants) => {
  let colorVariants = []

  variants.forEach((variant) => {
    variant?.option_values?.forEach((option) => {
      if (option?.option_display_name === 'Color') {
        if (!colorVariants?.includes(option.label)) {
          colorVariants?.push(option.label)
        }
      }
    })
  })

  return colorVariants
}

export const getProductUrl = (url) => {
  return url
  const url_path = new Url(url)
  return url_path.pathname
}

// Get the discount rules from the customer group
export const getGroupDiscountRules = (customerGroup) => {
  let siteWide = {
    rules: [],
  }
  let category = {
    rules: [],
  }
  let product = {
    rules: [],
  }
  let groupDiscount = {
    siteWide,
    category,
    product,
  }

  if (customerGroup != null) {
    customerGroup?.discount_rules?.forEach((rule) => {
      switch (rule.type) {
        case 'all':
          siteWide.rules.push(rule)
          break
        case 'category':
          category.rules.push(rule)
          break
        case 'product':
          product.rules.push(rule)
          break
        default:
      }
    })
  }

  return groupDiscount
}
// Get the default discount rule to apply
// Discount rules heirarchy product > category > all
export const getDefaultDiscountRule = (customerGroupDiscountRules, product) => {
  if (customerGroupDiscountRules.product.rules.length > 0) {
    let rule = customerGroupDiscountRules.product.rules.find(
      (rule) => rule.product_id == product.id
    )
    if (typeof rule !== 'undefined') {
      return rule
    }
  }

  if (customerGroupDiscountRules.category.rules.length > 0) {
    if (product.categories != null) {
      let rule = null
      product.categories.every((categoryId) => {
        let found = customerGroupDiscountRules.category.rules.find(
          (rule) => rule.category_id == categoryId
        )

        if (typeof found !== 'undefined') {
          rule = found
          return false
        }

        return true
      })

      if (rule != null) {
        return rule
      }
    }
  }

  if (customerGroupDiscountRules.siteWide.rules.length > 0) {
    return customerGroupDiscountRules.siteWide.rules[0]
  }

  return null
}

// format group discount
export const formatDiscount = (discount) => {
  if (typeof discount === 'undefined') return false
  // const partials = discount.split('.')
  // return parseInt(partials[0])
  return discount
}

// get category ids from category names
export const getCategoryIds = (categories, categoryNames) => {
  let categoryIds = []
  categoryNames.forEach((categoryName) => {
    let name = categoryName.replace('&amp;', '&')

    let categoryId = getCategoryId(name, categories)

    if (categoryId != null) {
      categoryIds.push(categoryId.id)
    }
  })

  return categoryIds
}

// get the category id from category nav
export const getCategoryId = (name, categories) => {
  const categoryId = categories.reduce((a, item) => {
    if (a) return a
    if (item.name == name) return item
    if (item.children) return getCategoryId(name, item.children)
  }, null)

  return categoryId
}

export const validCategories = () => {
  const categories = [
    'Bib Shorts',
    'Gloves & Hats',
    'Socks & Accessories',
    'Jerseys',
    'Shorts',
    'Jackets & Vests',
    'Baselayers',
    'Tights',
  ]
  return categories
}

export const getLowestSalePrice = (variants) => {
  let prices = {
    regularPrice: null,
    salePrice: null,
  }

  variants?.forEach((variant, index) => {
    if (index == 0) {
      prices.regularPrice = variant.price
      prices.salePrice = variant.sale_price
    } else {
      if (variant.sale_price != null) {
        if (prices.salePrice == null || prices.salePrice > variant.sale_price) {
          prices.salePrice = variant.sale_price
          prices.regularPrice = variant.price
        }
      }
    }
  })
  return prices
}

export const findLowestSalePrice = (product) => {
  let variantSalePrice = product?.variants?.filter((item) =>
    item?.option_values?.find(
      (col) => col?.option_display_name === 'Color' && col?.label === product?.color
    )
  )
  let lowestSalePrice = Number.MAX_SAFE_INTEGER
  // Iterate through the array
  variantSalePrice.forEach((item) => {
    // Check if sale_price is greater than 0 and lower than the current lowestSalePrice
    if (item?.sale_price > 0 && item?.sale_price < lowestSalePrice) {
      lowestSalePrice = item.sale_price
    }
  })
  return lowestSalePrice
}

export const getLowestSalePriceSku = (variant, colorName) => {
  let sameColorVariant = variant?.filter((item) => {
    const color = item?.option_values?.find((clor) => clor.option_display_name === 'Color')
    if (getColorName(color?.label) === colorName) {
      return item
    }
  })

  const filteredArray =
    sameColorVariant?.length &&
    sameColorVariant?.filter((item) => item?.sale_price !== 0 || item?.sale_price !== null)

  // Find the object with the lowest sale_price
  const lowestSalePriceObject =
    filteredArray?.length &&
    filteredArray.reduce((minItem, currentItem) => {
      return currentItem.sale_price < minItem.sale_price ? currentItem : minItem
    })
  return lowestSalePriceObject ? lowestSalePriceObject?.sku : ''
}

export const getAllCategoryPaths = () => {
  const categoryPaths = new Array()
  categoryNav.data.map((item) => {
    // if(item.name != "Activity" && item.name != "Technology") {
    categoryPaths.push({
      title: item.name,
      slug: item.url + 'all/',
    })
    if (item.children.length > 0) {
      item.children.map((i) => {
        categoryPaths.push({
          title: i.name,
          slug: i.url,
        })
        if (i.children.length > 0) {
          i.children.map((it) => {
            categoryPaths.push({
              title: it.name,
              slug: it.url,
            })
            if (it.children.length > 0) {
              it.children.map((itm) => {
                categoryPaths.push({
                  title: itm.name,
                  slug: itm.url,
                })
              })
            }
          })
        }
      })
    }
    // }
  })
  return categoryPaths
}

export const getAllSportPaths = () => {
  const category = categoryNav.data.find((d) => d.name == 'Activity')
  const categoryPaths = new Array()
  category.children.map((item) => {
    if (item.url.includes('activity')) {
      let urlSlug = item.url.split('activity')
      categoryPaths.push({
        title: item.name,
        slug: urlSlug[1],
      })
    }
    if (item.children) {
      item.children.map((data) => {
        if (data.url.includes('activity')) {
          let urlSlug = data.url.split('activity')
          categoryPaths.push({
            title: data.name,
            slug: urlSlug[1],
          })
        }
      })
    }
  })
  return categoryPaths
}

export const getCategoryIdByUrl = (url) => {
  const category = categoryNav.data.find((d) => d.name == 'Gender')
  const categoryId = new Array()
  const menCategory = category.children.find((d) => d.name == 'Shop Men')
  const womenCategory = category.children.find((d) => d.name == 'Shop Women')
  menCategory.children.map((item) => {
    if (item.url == url) {
      categoryId.push(item.id)
    }
  })
  if (categoryId.length == 0) {
    womenCategory.children.map((item) => {
      if (item.url == url) {
        categoryId.push(item.id)
      }
    })
  }
  return categoryId
}

export const getCategoryByUrl = (url) => {
  const categoryName = new Array()
  categoryNav.data.map((item) => {
    // if(item.name != "Activity" && item.name != "Technology") {
    if (item.url == url) {
      categoryName.push({ name: item.name, id: item.id })
    }
    if (item.children.length > 0) {
      item.children.map((i) => {
        if (i.url == url) {
          categoryName.push({ name: i.name, id: i.id })
        }
        if (i.children.length > 0) {
          i.children.map((it) => {
            if (it.url == url) {
              categoryName.push({ name: it.name, id: it.id })
            }
            if (it.children.length > 0) {
              it.children.map((itm) => {
                if (itm.url == url) {
                  categoryName.push({ name: itm.name, id: itm.id })
                }
              })
            }
          })
        }
      })
      // }
    }
  })
  return categoryName
}

const cleanSlash = (path) => {
  if (path.charAt(0) == '/') path = path.substr(1)
  if (path.charAt(path.length - 1) == '/') path = path.substr(0, path.length - 1)
  return path
}

export const getCategoryNameByUrl = (url, passURL) => {
  url = cleanSlash(url)
  const categoryName = new Array()
  categoryNav.data.map((item) => {
    // if(item.name != "Activity" && item.name != "Technology") {
    if (cleanSlash(item.url) == url) {
      if (passURL) {
        categoryName.push({
          label: item?.name,
          link: `${item?.url?.toLowerCase()}all/`,
        })
      } else {
        categoryName.push(item.name)
      }
    }
    if (item.children.length > 0) {
      item.children.map((i) => {
        if (cleanSlash(i.url) == url) {
          if (passURL) {
            categoryName.push({
              label: i?.name,
              link: `${i?.url?.toLowerCase()}`,
            })
          } else {
            categoryName.push(i.name)
          }
        }
        if (i.children.length > 0) {
          i.children.map((it) => {
            if (cleanSlash(it.url) == url) {
              if (passURL) {
                categoryName.push({
                  label: it?.name,
                  link: `${it?.url?.toLowerCase()}`,
                })
              } else {
                categoryName.push(it.name)
              }
            }
            if (it.children.length > 0) {
              it.children.map((itm) => {
                if (cleanSlash(itm.url) == url) {
                  if (passURL) {
                    categoryName.push({
                      label: itm?.name,
                      link: `${itm?.url?.toLowerCase()}`,
                    })
                  } else {
                    categoryName.push(itm.name)
                  }
                }
              })
            }
          })
        }
      })
      // }
    }
  })
  return categoryName
}

export const getParentCategoryIdByUrl = (url) => {
  const category = categoryNav.data.find((d) => d.name == 'Gender')
  const categoryId = new Array()
  const menCategory = category.children.find((d) => d.name == 'Shop Men')
  const womenCategory = category.children.find((d) => d.name == 'Shop Women')
  if (menCategory.url == url) {
    categoryId.push(menCategory.id)
  } else if (womenCategory.url == url) {
    categoryId.push(womenCategory.id)
  }
  return categoryId
}

export const getParentCategoryNameByUrl = (url) => {
  const category = categoryNav.data.find((d) => d.name == 'Gender')
  const categoryName = new Array()
  const menCategory = category.children.find((d) => d.name == 'Shop Men')
  const womenCategory = category.children.find((d) => d.name == 'Shop Women')
  if (menCategory.url == url) {
    categoryName.push(menCategory.name)
  } else if (womenCategory.url == url) {
    categoryName.push(womenCategory.name)
  }
  return categoryName
}

export const getCategoryNamesByIds = (ids) => {
  const categories = []
  for (let i in ids) {
    let id = ids[i]
    for (let item in categoryNav.data) {
      if (categoryNav.data[item].id == id) {
        categories.push(categoryNav.data[item])
        break
      } else if (categoryNav.data[item].children) {
        let category = categoryNav.data[item].children
        for (let item in category) {
          if (category[item].id == id) {
            categories.push(category[item])
            break
          } else if (category[item].children) {
            let categ = category[item].children
            for (let item in categ) {
              if (categ[item].id == id) {
                categories.push(categ[item])
                break
              } else if (categ[item].children) {
                let cat = categ[item].children
                for (let item in cat) {
                  if (cat[item].id == id) {
                    categories.push(cat[item])
                    break
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return categories
}

// sort variants based on the BC options config
export const sortVariants = (product) => {
  let variants = product.variants
  let options = product.options
  let optionLabels = {}
  let optionsOrder = []

  options?.forEach((option, index) => {
    optionLabels[option.display_name] = []
    option.option_values.forEach((optionValue) => {
      optionLabels[option.display_name].push(optionValue.label)
    })
  })

  let optionLabelKeys = Object.keys(optionLabels)

  if (optionLabelKeys.length > 1) {
    optionLabels[optionLabelKeys[0]].forEach((option1) => {
      optionLabels[optionLabelKeys[1]].forEach((option2) => {
        optionsOrder.push({
          [optionLabelKeys[0]]: option1,
          [optionLabelKeys[1]]: option2,
        })
      })
    })

    let sortedVariants = optionsOrder
      .map((item) => {
        let optionKeys = Object.keys(item)
        let selectedVariant = null
        // find this combination
        variants.forEach((variant) => {
          let optionMatch = {
            [optionLabelKeys[0]]: false,
            [optionLabelKeys[1]]: false,
          }

          optionKeys.forEach((key) => {
            let option = variant.option_values.find((option) => option.option_display_name === key)

            if (!isEmpty(option) && option.label === item[key]) {
              optionMatch[key] = true
            }
          })

          if (optionMatch[optionLabelKeys[0]] && optionMatch[optionLabelKeys[1]]) {
            selectedVariant = variant
          }
        })

        if (!isEmpty(selectedVariant)) {
          return selectedVariant
        }
      })
      .filter((el) => el)

    return sortedVariants
  }

  return variants
}

// get the default option configuration
export const getDefaultProductOptions = (options) => {
  let optionLabels = {}

  options.forEach((option) => {
    optionLabels[option.display_name] = null
    option.option_values.forEach((optionValue) => {
      if (optionValue.is_default) {
        optionLabels[option.display_name] = optionValue.label
      }
    })
  })

  return optionLabels
}

// get the default option based on BC default option config
export const getDefaultVariant = (variants, defaultOptions) => {
  let filteredDefaultOptions = { ...defaultOptions }
  let filteredKeys = Object.keys(filteredDefaultOptions)

  filteredKeys.forEach((key) => {
    if (filteredDefaultOptions[key] == null) {
      delete filteredDefaultOptions[key]
    }
  })

  filteredKeys = Object.keys(filteredDefaultOptions)

  if (filteredKeys.length == 0) {
    return variants[0]
  }

  // all variants that match the first default option
  let possibleVariants = variants.filter((variant) => {
    let match = false

    for (let optionValue of variant.option_values) {
      if (
        optionValue.option_display_name === filteredKeys[0] &&
        optionValue.label === filteredDefaultOptions[filteredKeys[0]]
      ) {
        match = true
      }
    }
    if (match) return variant
  })

  if (possibleVariants.length == 0) {
    return variants[0]
  }

  // find the variant that matches the options
  let defaultVariant = possibleVariants.find((variant) => {
    let matches = 0
    filteredKeys.forEach((key) => {
      for (let optionValue of variant.option_values) {
        if (
          optionValue.option_display_name === key &&
          optionValue.label === filteredDefaultOptions[key]
        ) {
          matches++
          break
        }
      }
    })
    if (matches == filteredKeys.length) {
      return variant
    }
  })

  if (!isEmpty(defaultVariant)) return defaultVariant

  //return the next available variant
  return possibleVariants[0]
}

// get variant by the choices combination
export const getVariantByChoice = (choices, variants) => {
  let choicesKeys = Object.keys(choices)

  let variant = variants.find((variant) => {
    let matched = 0

    choicesKeys.forEach((key) => {
      variant.option_values.find((option) => {
        if (option.option_display_name == key && option.label == choices[key]) {
          matched++
        }
      })
    })

    if (choicesKeys.length == matched) {
      return variant
    }
  })

  return variant
}

// set choices based on the color and select option of the first variant
export const setFirstChoiceByColor = (color, variants) => {
  let variant = variants.find((variant) => {
    let option = variant.option_values.find((option) => {
      if (option.option_display_name == 'Color' && option.label == color) {
        return option
      }
    })

    if (!isEmpty(option)) {
      return variant
    }
  })

  if (!isEmpty(variant)) {
    let choices = {}
    variant.option_values.forEach((option) => {
      choices[option.option_display_name] = option.label
    })

    return choices
  }
  return null
}

// get the next available variant if the selected variant is not out of stock
export const getNextAvailableVariant = (label, variants) => {
  let variant = variants.find((variant) => {
    let option = variant.option_values.find((option) => option.label == label)

    if (!isEmpty(option) && variant.inventory_level > 0) {
      return variant
    }
  })

  return variant
}

export const getParentCategoryKey = () => {
  const categoryKeys = []
  categoryNav.data.map((category) => {
    categoryKeys.push(category.url.replace(/\//g, ''))
  })
  return categoryKeys
}

export const getProductCategory = (product) => {
  const categoryField = product?.metafields?.filter((item) => item?.key === 'sitka_category')

  try {
    const category = JSON.parse(categoryField?.[0]?.value)
    return category.en
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getMainImageByCode = (images, code) => {
  if (images && images.length > 0) {
    const image = images.find((image) => {
      if (isJsonString(image.description)) {
        let description = JSON.parse(image.description)

        return (
          description?.colorCode &&
          description?.colorCode == code &&
          description?.number == '1' &&
          !description.isModel
        )
      }
    })

    return image ? image : null
  }

  return null
}

export const getLifestyleImagesByVariant = (images, selectedOptions) => {
  if (selectedOptions['Color'] && images?.length > 0) {
    const color = selectedOptions['Color']?.label

    let fallbackImages = []

    let lifestyleImages = images.filter((image) => {
      if (isJsonString(image?.description)) {
        const description = JSON.parse(image?.description)

        if (description?.colorName == color && description?.default) {
          fallbackImages.push(image)
        }

        if (description?.colorName == color && description?.islifestyle) {
          return image
        }
      }
      return false
    })

    if (lifestyleImages?.length == 2) {
      return lifestyleImages
    }

    let lifeAndFallback = [...lifestyleImages, ...fallbackImages]

    if (lifeAndFallback?.length == 2) {
      return lifeAndFallback
    }
  }

  return []
}

export const isProductImported = (product) => {
  const key = 'us_import_description'

  if (product?.custom_fields) {
    const isImported = product?.custom_fields?.find((item) => item.name == key)

    if (isImported) {
      return true
    }
  }
  return false
}

export const getCategoryData = (id) => {
  let returncategory = null
  categoryNav.data.map((item) => {
    if (item.id == id) {
      returncategory = item
    }
    if (item.children.length > 0) {
      item.children.map((i) => {
        if (i.id == id) {
          returncategory = i
        }
        if (i.children.length > 0) {
          i.children.map((it) => {
            if (it.id == id) {
              returncategory = it
            }
            if (it.children.length > 0) {
              it.children.map((itm) => {
                if (itm.id == id) {
                  returncategory = itm
                }
              })
            }
          })
        }
      })
    }
  })
  return returncategory
}

export const getCategoryBatchData = (id) => {
  let category = categoryBatch.find(cat => cat.category_id == id)
  return category
}

export const setCookiesForRecs = (sku) => {
  let recsCookie = Cookies.get('previouslyViewed')
  if (recsCookie) {
    if (recsCookie.includes(',')) {
      let splitSku = recsCookie.split(',')

      if (splitSku.length >= 10 && !recsCookie.includes(sku)) {
        let newSplitSku = splitSku.slice(0, -1) // remove last element ['50039', '905733', empty]
        let newJoinedSku = sku + ',' + newSplitSku.join(',')
        Cookies.set('previouslyViewed', newJoinedSku)
      } else if (!recsCookie.includes(sku)) {
        let newJoinedSku = sku + ',' + recsCookie
        Cookies.set('previouslyViewed', newJoinedSku)
      }
    } else if (!recsCookie.includes(sku)) {
      let newJoinedSku = sku + ',' + recsCookie
      Cookies.set('previouslyViewed', newJoinedSku)
    }
  } else {
    Cookies.set('previouslyViewed', sku)
  }
}

export const getCookieForRecs = () => {
  let recsCookie = Cookies.get('previouslyViewed')
  return recsCookie
}

export const getAvailableColorsPlp = (inventory, product) => {
  let availableColors = []
  let colorsPrices = []
  if (inventory.length > 0) {
    const availableVariantId = inventory.map((item) => {
      return item.variant_id
    })

    if (availableVariantId && availableVariantId.length > 0) {
      product.variants.map((item) => {
        // if (availableVariantId.includes(String(item.id))) {
        // also display the swatches even if it's not in warehouse but are in BC store ( SITKAHYPER-264 )
        let colorOption = item.option_values.find(
          (opt) => opt.option_display_name == 'Color'
        )?.label
        // ( SITKA-2718 )
        if (colorOption && !availableColors.includes(colorOption) && !item.purchasing_disabled) {
          availableColors.push(colorOption)
          colorsPrices.push({ color: colorOption, price: item.price, sale_price: item.sale_price })
        }
        // }
      })
    }
  } else {
    product.variants.map((item) => {
      let colorOption = item.option_values.find((opt) => opt.option_display_name == 'Color')?.label
      if (colorOption && !availableColors.includes(colorOption)) {
        availableColors.push(colorOption)
        colorsPrices.push({ color: colorOption, price: item.price, sale_price: item.sale_price })
      }
    })
  }
  return {
    availableColorsPlp: availableColors,
    colorsPrices: colorsPrices,
  }
}

export const getBrand = (id) => {
  return ''
  const brand = brandData?.find((item) => item.id == id)
  return brand?.name || ''
}

export const setImageWidth = (imageUrl, size) => {
  let regex = /\/[0-9]+w\//

  if (regex.test(imageUrl)) {
    return imageUrl.replace(regex, `/${size}w/`)
  }

  return imageUrl
}

export const setLastViewedColorCookie = (selectedOption, sku) => {
  try {
    const color = selectedOption?.Color?.label
    const colorValue = `${color}-${sku}`

    if (color) {
      let colorCookie = Cookies.get('previouslyViewedColor')
      let previouslyViewed = Cookies.get('previouslyViewed')

      console.debug('==', colorCookie, previouslyViewed)
      if (previouslyViewed) {
        let previouslyViewedArray = previouslyViewed?.split(',')

        if (previouslyViewedArray?.includes(sku)) {
          if (colorCookie) {
            let colorCookieArray = colorCookie?.split(',')
            let currentProductIndex = colorCookieArray?.findIndex((item) => item.endsWith(sku))

            if (currentProductIndex != -1) {
              colorCookieArray[currentProductIndex] = colorValue
            } else {
              colorCookieArray = [colorValue, ...colorCookieArray]
            }

            Cookies.set(
              'previouslyViewedColor',
              colorCookieArray
                ?.filter((item) => {
                  let itemPartials = item?.split('-')
                  return previouslyViewedArray.includes(itemPartials?.[1])
                })
                .join(',')
            )
          } else {
            Cookies.set('previouslyViewedColor', colorValue)
          }
        }
      }
    }
  } catch (error) {
    console.log('setLastViewedColorCookie error', error)
  }
}

export const getLastViewedColorCookieValue = (sku) => {
  try {
    let colorCookie = Cookies.get('previouslyViewedColor')

    if (colorCookie) {
      let colorCookieArray = colorCookie?.split(',')

      let colorValue = colorCookieArray.find((item) => item?.endsWith(sku))

      if (colorValue) {
        let colorPartials = colorValue?.split('-')

        if (colorPartials[0]) {
          return colorPartials[0]
        }
      }
    }

    return null
  } catch (error) {
    console.log('getLastViewedColorCookieValue error', error)
  }
}

export const getSkuType = (sku) => {
  const skuPartials = sku?.split('-')

  if (skuPartials?.length == 1) {
    return 'parent'
  }

  if (skuPartials?.length == 2) {
    return 'colorway'
  }

  if (skuPartials?.length == 3) {
    return 'variant'
  }

  return null
}

export const skuInFieldValues = (sku, customFieldsValues) => {
  return customFieldsValues?.find((field) => {
    let skuType = getSkuType(field)

    // compare the sku directly
    if (skuType == 'variant') {
      return field == sku
    }

    // if custom field value is a colorway get variant colorway sku and compare
    if (skuType == 'colorway') {
      let skuPartials = sku?.split('-')

      if (skuPartials[1]) {
        let skuColorway = `${skuPartials[0]}-${skuPartials[1]}`

        return field == skuColorway
      }
    }

    return false
  })
}

export const isSkuValid = (product, customFieldValue, checkInventory = false) => {
  const sku = product?.sku
  const skuType = getSkuType(sku)
  const customFieldArray = customFieldValue?.split(',')
  const variants = product?.variants || product?.variantsData

  if (customFieldArray?.length > 0) {
    if (skuType == 'parent') {
      let validVariant = false
      let filteredVariants = variants?.filter((variant) => {
        if (checkInventory) {
          return !variant?.purchasing_disabled && variant?.inventory_level > 0
        } else {
          return !variant?.purchasing_disabled
        }
      })

      if (filteredVariants?.length > 0) {
        for (let variant of filteredVariants) {
          if (skuInFieldValues(variant?.sku, customFieldArray)) {
            validVariant = true
          }
        }
      }

      return validVariant
    }

    if (skuType == 'variant' && skuInFieldValues(sku, customFieldArray)) {
      if (checkInventory) {
        let variant = variants?.find((variant) => variant?.sku == sku)

        if (variant && variant?.inventory_level > 0) {
          return true
        } else {
          return false
        }
      }
      return true
    }
  }

  return false
}

export const getTechBadgeValue = (product) => {
  const customFields = product?.custom_fields

  try {
    if (customFields) {
      const badgeField = customFields?.find((field) => field?.name == 'technology_badges')

      if (badgeField?.value) {
        const badgeData = badgeField?.value?.split(',')
        if (badgeData.includes('PRIMALOFT®')) {
          return 'PRIMALOFT®'
        }
        if (badgeData.includes('INSECT SHIELD®')) {
          return 'INSECT SHIELD®'
        }
        if (badgeData.includes('POLYGIENE®')) {
          return 'POLYGIENE®'
        }
        if (badgeData.includes('WINDSTOPPER®️ by GORE Labs')) {
          return 'WINDSTOPPER®️ by GORE Labs'
        }
        if (badgeData.includes('Aerolite Collection')) {
          return 'Aerolite Collection'
        }
        if (badgeData.includes('POLARTEC®')) {
          return 'POLARTEC®'
        }
        if (badgeData.includes('GORE-TEX®')) {
          return 'GORE-TEX®'
        }
      }
    }

    return null
  } catch (error) {
    console.log('getTechBadgeValue error', error)
    return null
  }
}

export const getBadgeValue = async (product, variantSku) => {
  const customFields = product?.custom_fields
  const variants = product?.variants || product?.variantsData
  const variant = variants?.find((variant) => variant?.sku == variantSku)

  try {
    if (customFields) {
      const requiredFields = [
        'badge_exclusive',
        'badge_exclusive_variants',
        'badge_new',
        'badge_new_color',
        'badge_new_color_variants',
        'badge_custom',
      ]

      const badgeStart = customFields?.find((field) => field?.name == 'badge_start')
      const badgeEnd = customFields?.find((field) => field?.name == 'badge_end')

      const badgeStartValue = badgeStart ? isDateValid(badgeStart?.value) : null
      const badgeEndValue = badgeEnd ? isDateValid(badgeEnd?.value) : null

      const displayBadge = displayWithCurrentDate(badgeStartValue, badgeEndValue)

      if (displayBadge) {
        let badgeFields = {}

        customFields?.forEach((field) => {
          if (requiredFields?.includes(field?.name)) {
            badgeFields[field?.name] = field?.value
          }
        })

        if (badgeFields['badge_custom']) {
          const customBadge = await getCustomBadgeData(badgeFields['badge_custom'])

          if (customBadge) {
            return customBadge?.badgeCopy
          }
        }

        if (
          badgeFields['badge_exclusive'] &&
          badgeFields['badge_exclusive']?.toLowerCase() == 'yes'
        ) {
          return 'Exclusive'
        }

        if (
          badgeFields['badge_exclusive_variants'] &&
          badgeFields['badge_exclusive_variants'].trim()?.length > 0
        ) {
          let sku = variant ? variant?.sku : product?.sku

          if (
            isSkuValid(
              {
                ...product,
                sku: sku,
              },
              badgeFields['badge_exclusive_variants']
            )
          ) {
            return 'Exclusive'
          }
        }

        if (badgeFields['badge_new'] && badgeFields['badge_new']?.toLowerCase() == 'yes') {
          return 'New'
        }

        if (
          badgeFields['badge_new_color'] &&
          badgeFields['badge_new_color']?.toLowerCase() == 'yes'
        ) {
          let inStock = false

          const validVariants = variants?.filter((variant) => !variant?.purchasing_disabled)

          for (let variant of validVariants) {
            if (variant?.inventory_level > 0) {
              inStock = true
              break
            }
          }

          if (inStock) {
            return 'New Color'
          }
        }

        if (
          badgeFields['badge_new_color_variants'] &&
          badgeFields['badge_new_color_variants'].trim()?.length > 0
        ) {
          let sku = variant?.sku || variantSku || product?.sku

          if (
            isSkuValid(
              {
                ...product,
                sku: sku,
              },
              badgeFields['badge_new_color_variants']
            )
          ) {
            return 'New Color'
          }
        }
      }
    }

    if (variant) {
      if (variant?.price != variant?.calculated_price) {
        let discount = getDiscount(variant?.price, variant?.calculated_price)

        if (discount && discount > 0 && !isNaN(discount)) {
          if (discount < 25) {
            return 'Sale'
          }
          return `${discount}% OFF `
        }
      }
    }

    return null
  } catch (error) {
    console.log('getBadgeValue error', error)
    return null
  }
}

export const getProductGroupDiscounts = (customFields) => {
  try {
    const productDiscounts = customFields?.filter((field) =>
      field?.name?.startsWith('product_discount')
    )

    if (!productDiscounts) {
      return {}
    }

    let groupDiscounts = {}
    let uniqueKeys = new Set()

    productDiscounts.forEach((field) => {
      if (isJsonString(field?.value)) {
        let fieldValue = JSON.parse(field?.value)
        Object.keys(fieldValue).forEach((key) => {
          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key)
            groupDiscounts[key] = fieldValue[key]
          }
        })
      }
    })

    return groupDiscounts
  } catch (error) {
    console.log('getGroupDiscounts error', error)

    return {}
  }
}

export const getProductDiscountByGroup = (customFields, groupName) => {
  try {
    const groupDiscounts = getProductGroupDiscounts(customFields)

    return groupDiscounts[groupName] || null
  } catch (error) {
    console.log('getProductDiscountByGroup error', error)
  }
}
