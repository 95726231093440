import axios from 'axios'

export const trackEvent = async (payload) => {
  try {
    if ((payload?.email || payload?.customerId) && payload?.event) {
      const response = await axios.post(
        `${
          process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
        }/api/track/event`,
        payload
      )

      return response
    }

    throw new Error('Missing required event data')
  } catch (error) {
    console.log('trackEvent error:', error)
  }
}
