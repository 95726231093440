import Cookies from 'js-cookie'
import axios from 'axios'
import { authCreate } from '../client/authRepairClient'
import { adminCreate } from '../client/adminClient'

export const authClient = authCreate()
export const adminClient = adminCreate()

export const RepairService = {
  async getRepairs(paramsData) {
    const customerToken = Cookies.get('customer_token')
    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }
    try {
      const response = await authClient.get(`/getRepairList?` + new URLSearchParams(paramsData))
      return response.data
    } catch (error) {
      console.log(`Error in repair list API call`, error)
      throw error.response
    }
  },

  async getRepairQuestionsById(id) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!id) {
      throw new Error('PLease provide repair id.')
    }
    try {
      const response = await authClient.get(`/getRepairQuestions/${id}`)
      return response.data
    } catch (error) {
      console.log(`Error in repair Questions by id API call`, error)
      throw error.response
    }
  },

  async getRepairById(id, uploadImage) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!id) {
      throw new Error('PLease provide repair id.')
    }

    try {
      const response = await authClient.get(`/getRepair/${id}?upload_image=${uploadImage}`)
      return response.data
    } catch (error) {
      console.log(`Error in repair detail API call`, error)
      throw error.response
    }
  },

  async getTrackerRepairById(id) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!id) {
      throw new Error('PLease provide repair id.')
    }

    try {
      const response = await authClient.get(`/getrepair/basic-detail/${id}`)
      return response.data
    } catch (error) {
      console.log(`Error in repair tracker detail API call`, error)
      throw error.response
    }
  },

  async createDraftRepair(draftRepairData) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!draftRepairData.repair || !draftRepairData.product || !draftRepairData.customer) {
      throw new Error('Please provide repair data')
    }

    try {
      const response = await authClient.post('/createRepair', draftRepairData)
      return response.data
    } catch (error) {
      console.log(`Error in creating repair API call`, error)
      throw error.response
    }
  },

  async updateDraftRepairProduct(draftRepairData) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!draftRepairData.repair_id || !draftRepairData.product) {
      throw new Error('Please provide repair data')
    }

    try {
      const response = await authClient.put(
        `/updateRepairProduct/${draftRepairData.repair_id}`,
        draftRepairData
      )
      return response.data
    } catch (error) {
      console.log(`Error in updating repair API call`, error)
      throw error.response
    }
  },

  async updateCustomerInfo(id, payload) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    try {
      const response = await authClient.put(`/repair/customer-info/${id}`, payload)
      return response.data
    } catch (error) {
      console.log(`Error in updating customer info in repair API call`, error)
      throw error.response
    }
  },

  async updateCustomerAddressInfo(id, payload) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    try {
      const response = await authClient.put(`/repair/address-update-new/${id}`, payload)
      return response.data
    } catch (error) {
      console.log(`Error in updating customer address in repair API call`, error)
      throw error.response
    }
  },

  async uploadImage(data, file) {
    try {
      if (data?.fields) {
        let form = new FormData()
        Object.keys(data?.fields).forEach((key) => form.append(key, data?.fields[key]))
        form.append('file', file)
        const data2 = await axios.post(data.url, form)
        console.log('data2', data2)
      }
    } catch (error) {
      console.log('error', error)
      console.error(`Error uploading image/files`, error)
    }
  },

  async getPreSignedUrl(bodyParams) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!bodyParams) {
      throw new Error('Please provide params to upload image')
    }

    try {
      const response = await authClient.post(`/signedUrl`, bodyParams)
      return response.data
    } catch (error) {
      console.log(`Error in updating customer info API call`, error)
      throw error.response
    }
  },

  async getS3SignUrl(file, extension, type, isUploaded) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!file) {
      throw new Error('Please provide file to upload')
    }

    const params = {
      image_type: type,
      image_name: file.name,
      image_extension: extension,
    }

    try {
      const res = await RepairService.getPreSignedUrl(params)
      if (res) {
        const signedData = res.data
        isUploaded && this.uploadImage(res.data, file)
        return signedData
      }
    } catch (error) {
      console.error(`Error uploading image`, error)
    }
  },

  async updateDraftRepairFiles(files, repairId) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!files || !repairId) {
      throw new Error('Please provide files and repair id')
    }

    //   {
    //     "full_product":{
    //         "id":0,
    //         "file":"filePath",
    //         "is_delete":false
    //     },
    //     "product_tag":{
    //         "id":0,
    //         "file":"filePath",
    //         "is_delete":false
    //     },
    //     "warrenty_issue":{
    //         "id":0,
    //         "file":"filePath"
    //     },
    //     "proof_of_purchase":{
    //         "id":0,
    //         "file":"filePath",
    //         "is_delete":false
    //     },
    //     "additional_photo":[
    //         {
    //             "id":15,
    //             "file":"filePath",
    //             "is_delete":false
    //         }
    //     ]
    // }

    try {
      const response = await authClient.put(`/uploadRepairFiles/${repairId}`, files)
      return response.data
    } catch (error) {
      console.log(`Error in updating draft repair files API call`, error)
      throw error.response
    }
  },

  async updateRepairQuestions(questions, repairId) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!questions || !repairId) {
      throw new Error('Please provide questions and repair id')
    }

    // {
    //   "repairAnswers":[
    //       {
    //           "id":0,
    //           "question_id":1,
    //           "answer_id":1
    //       },
    //       {
    //           "id":0,
    //           "question_id":2,
    //           "answer_id":7
    //       },
    //       {
    //           "id":0,
    //           "question_id":3,
    //           "answer_id":12
    //       }
    //   ]
    // }

    try {
      const response = await authClient.put(`/updateRepairQuestions/${repairId}`, questions)
      return response.data
    } catch (error) {
      console.log(`Error in updating draft repair question API call`, error)
      throw error.response
    }
  },

  async submitRepair(repairId) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!repairId) {
      throw new Error('Please provide repair id')
    }

    try {
      const response = await authClient.put(`/submitRepair/${repairId}`)
      return response.data
    } catch (error) {
      console.log(`Error in Submit repair API call`, error)
      throw error.response
    }
  },

  async getAllQuestionsList() {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    try {
      const response = await authClient.get('/getQuestionsList')
      return response.data
    } catch (error) {
      console.log(`Error in get repair question list API call`, error)
      throw error.response
    }
  },

  async updateRepairDamages(repairDamage, repairId) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!repairId) {
      throw new Error('Please provide repair id')
    }

    try {
      const response = await authClient.put(`/updateRepairDamages/${repairId}`, repairDamage)
      return response.data
    } catch (error) {
      console.log(`Error in update repair damage API call`, error)
      throw error.response
    }
  },

  async getRepairDamages(id) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!id) {
      throw new Error('PLease provide repair id.')
    }

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${customerToken}`,
      },
    }

    try {
      const response = await authClient.get(`/getRepairDamages/${id}`)
      return response.data
    } catch (error) {
      console.log(`Error in get repair Damage API call`, error)
      throw error.response
    }
  },

  async deleteRepairDamage(id) {
    const customerToken = Cookies.get('customer_token')

    if (!customerToken) {
      throw new Error('customer_token cookie not found. Authentification failed.')
    }

    if (!id) {
      throw new Error('PLease provide damage id.')
    }

    try {
      const response = await authClient.delete(`/deletDamageDetails/${id}`)
      return response.data
    } catch (error) {
      console.log(`Error in delete repair damage list API call`, error)
      throw error.response
    }
  },

  // Commented not using this
  // async getFedexLabel(id) {
  //   const customerToken = Cookies.get('customer_token')

  //   if (!customerToken) {
  //     throw new Error('customer_token cookie not found. Authentification failed.')
  //   }

  //   if (!id) {
  //     throw new Error('PLease provide repair id.')
  //   }

  //   const params = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${customerToken}`,
  //     },
  //   }

  //   const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/getFedexLabel/${id}`, params)

  //   const data = await response.json()
  //   return data
  // },

  // Commented not using this
  // async doNotSellInfoCustomer(notSellInfoCustomer) {
  //   const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/optOutDetails`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(notSellInfoCustomer),
  //   })
  //   const data = await response.json()
  //   return data
  // },

  async getDamageWarrantyIssue() {
    try {
      const response = await adminClient.get('/damageWarrantyissue')
      return response.data
    } catch (error) {
      console.log(`Error in get Damage warranty issue  list API call`, error)
      throw error.response
    }
  },

  async getDamageWarrantySpecificIssue(id) {
    try {
      const response = await adminClient.get(`damageWarrantySpecificIssue/${id}`)
      return response.status === 200 ? response.data : { data: [] }
    } catch (error) {
      console.log(`Error in get Damage warranty Specific issue  list API call`, error)
      throw error.response
    }
  },

  async getDamageWarrantyLocationIssue(id) {
    try {
      const response = await adminClient.get(`damageWarrantyLocation/${id}`)
      return response.status === 200 ? response.data : { data: [] }
    } catch (error) {
      console.log(`Error in get Damage warranty Location issue list API call`, error)
      throw error.response
    }
  },
}
