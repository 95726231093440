const buttons = () =>{
  return ({ addComponents, config}) => {
    addComponents([
      {
        '.btn, .Btn': {
          border: `solid 1px transparent`,
          display: 'inline-block',
          cursor: 'pointer',
          fontSize: '12px',
          fontWeight: 'bold',
          lineHeight: '1',
          outline: 'none',
          padding: '18px 0px',
          textAlign: 'center',
          textTransform: 'uppercase',
          transition: 'all .1s ease-out'
        },
        '.btn:focus, .Btn:focus': {
          outline: 'none'
        },
        '.btn-primary, .Btn-primary': {
          backgroundColor: config().theme.colors.primary,
          border: `solid 1px ${config().theme.colors.primary}`,
          color: config().theme.colors.black,
          '&:hover': {
            backgroundColor: config().theme.colors.primaryHover,
            border: `solid 1px ${config().theme.colors.primaryHover}`
          }
        },
        '.btn-primary-outline .Btn-primary-outline': {
          backgroundColor: 'transparent',
          border: `solid 1px ${config().theme.colors.secondary}`,
          color: config().theme.colors.secondary,
          '&:hover': {
            border: `solid 1px ${config().theme.colors.primary}`,
            color: `${config().theme.colors.primary}`
          }
        },
        '.btn-atc-primary': {
          backgroundColor: config().theme.colors.atcPrimary,
          border: `solid 1px ${config().theme.colors.atcPrimary}`,
          color: config().theme.colors.white,
          '&:hover': {
            backgroundColor: config().theme.colors.atcPrimaryHover,
            border: `solid 1px ${config().theme.colors.atcPrimaryHover}`
          }
        },
        '.btn-outline': {
          backgroundColor: 'transparent',
          border: `solid 1px ${config().theme.colors.gray['200']}`,
          color: config().theme.colors.white,
          padding: '18px 35px',
          borderRadius: '5px'
        },
        '.btn-outline-black': {
          backgroundColor: 'transparent',
          border: `solid 1px ${config().theme.colors.black}`,
          color: config().theme.colors.black,
          padding: '18px 35px',
          borderRadius: '5px'
        },
        '.btn-outline-gray': {
          backgroundColor: 'white',
          border: `solid 1px ${config().theme.colors.gray['400']}`,
          color: config().theme.colors.gray['400'],
          padding: '18px 35px',
          borderRadius: '5px'
        },
        '.btn-secondary': {
          backgroundColor: config().theme.colors.mutedBlue,
          border: `solid 1px ${config().theme.colors.mutedBlue}`,
          color: config().theme.colors.black,
          '&:hover': {
            backgroundColor: config().theme.colors.mutedBlueHover,
            border: `solid 1px ${config().theme.colors.mutedBlueHover}`
          }
        },
        '.btn-white': {
          backgroundColor: config().theme.colors.white,
          border: `solid 1px ${config().theme.colors.white}`,
          color: config().theme.colors.black,
          borderRadius: '2px'
        }
      }
    ])
  }
}

module.exports = buttons;