import * as types from '../actionTypes'
import { getSizeChartById } from '@/lib/contentful/size-chart'

export const updateCartModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_CART_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateLoginModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_LOGIN_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateCreateModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_SIGNUP_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateResetPasswordModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_RESET_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const redirectToMainPage = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.REDIRECT_TO_MAIN_PAGE_SUCCESS,
    payload: isOpen,
  })
}

export const loadSizeChart = (productId, sizeChartId) => async (dispatch) => {
  try {
    if (!productId) {
      throw new Error('productId is required')
    }

    let { data: sizeChart } = await getSizeChartById(sizeChartId)

    if (sizeChart) {
      let sizeChartData = {
        productId: productId,
        data: sizeChart?.fields ?? null,
      }

      await dispatch({
        type: types.LOAD_SIZE_CHART_SUCCESS,
        payload: sizeChartData,
      })

      return sizeChartData
    }

    return null
  } catch (error) {
    await dispatch({
      type: types.LOAD_SIZE_CHART_REQUEST_FAILURE,
    })
    console.log('loadSizeChart error', error)
  }
}

export const updateSizeChart = (productId, sizeChart) => async (dispatch) => {
  await dispatch({
    type: types.LOAD_SIZE_CHART_SUCCESS,
    payload: {
      productId,
      data: sizeChart,
    },
  })
}
