import axios from 'axios'

export const getInlineBannersData = async (ids) => {
  try {
    const { data } = await axios.post(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
      }/api/contentful/banner/inline`,
      { ids: ids }
    )

    return data
  } catch (error) {
    console.log('getInlineBanners error', error)
  }
}
