import { default as categoryNav } from '@/lib/bigcommerce/data/category-nav.json'
import { default as swatches } from '@/lib/bigcommerce/data/swatches.json'
import { default as reviewsCount } from '@/lib/bigcommerce/data/product-reviews.json'
import breadCrumbCategory from '../../public/breadcrumb-sort.json'

import { isEmpty } from './objects'
import { getCategoryNameByUrl } from '../product-helper'

export const getAvailableVariants = (variants) =>
  variants?.filter(
    (variant) => variant.purchasing_disabled === false && variant.inventory_level > 0
  )

export const buildCurrentVariant = (variants, variantIndex = 0) => {
  let currentVariant = getAvailableVariants(variants)?.[variantIndex]
  return currentVariant || null
}

// sort variants based on the BC options config
export const sortVariants = (product) => {
  let variants = product.variants
  let options = product.options
  let optionLabels = {}
  let optionsOrder = []

  options?.forEach((option) => {
    optionLabels[option.display_name] = []
    option.option_values.forEach((optionValue) => {
      optionLabels[option.display_name].push(optionValue.label)
    })
  })

  let optionLabelKeys = Object.keys(optionLabels)

  if (optionLabelKeys.length > 1) {
    optionLabels[optionLabelKeys[0]].forEach((option1) => {
      optionLabels[optionLabelKeys[1]].forEach((option2) => {
        optionsOrder.push({
          [optionLabelKeys[0]]: option1,
          [optionLabelKeys[1]]: option2,
        })
      })
    })

    let sortedVariants = optionsOrder
      .map((item) => {
        let optionKeys = Object.keys(item)
        let selectedVariant = null
        // find this combination
        variants.forEach((variant) => {
          let optionMatch = {
            [optionLabelKeys[0]]: false,
            [optionLabelKeys[1]]: false,
          }

          optionKeys.forEach((key) => {
            let option = variant.option_values.find((option) => option.option_display_name === key)

            if (!isEmpty(option) && option.label === item[key]) {
              optionMatch[key] = true
            }
          })

          if (optionMatch[optionLabelKeys[0]] && optionMatch[optionLabelKeys[1]]) {
            selectedVariant = variant
          }
        })

        if (!isEmpty(selectedVariant)) {
          return selectedVariant
        }
      })
      .filter((el) => el)

    return sortedVariants
  }

  return variants
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

/**
  Builds a breadcrumb array for the product page.
  1) (if on DT) Initializes the homepage breadcrumb.
  2) (if on DT) Tries to find the parent category by searching the product cateogries for a match in the categoryNav data.
  3) Tries to find a child category by searching the product metafields for a match in the parent category children.
    a) Only looks at the first metafield value.
  @param product - the product object
  @returns array of breadcrumb objects with label and link properties
*/

export const generateBreadCrumbs = (product, isMobile) => {
  let breadCrumbs = isMobile ? [] : [{ label: 'Homepage', link: '/' }]
  const key = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? 'prod_id' : 'stage_id'

  // find first match from breadCrumb json
  const firstMatch = breadCrumbCategory.find((item) =>
    product?.categories?.some((obj) => obj === item[key])
  )

  // split with '/'
  let array1 = {
    slug: firstMatch?.url?.split('/').filter((item) => item !== '') || '',
  }

  // get array of the name and url for all the categories
  const breadCrumbsData = getBackgroundFilter(array1, true)

  breadCrumbs = [...breadCrumbs, ...breadCrumbsData]
  return breadCrumbs
}

export const getBackgroundFilter = (params, passURL) => {
  let categoryName = new Array()
  if (params.slug.length > 1) {
    let slug = '/'
    let bgFilter = ''
    let count = 0

    if (params.slug.length == 2 && params.slug[1] == 'all') {
      slug = slug + params.slug[0] + '/'
      categoryName = [...categoryName, ...getCategoryNameByUrl(slug, passURL)]
    } else {
      params.slug.map((item) => {
        count = count + 1
        slug = slug + item + '/'

        if (bgFilter == '') {
          categoryName = [...categoryName, ...getCategoryNameByUrl(slug, passURL)]
        } else if (params.slug.length == count) {
          categoryName = [...categoryName, ...getCategoryNameByUrl(slug, passURL)]
        } else {
          categoryName = [...categoryName, ...getCategoryNameByUrl(slug, passURL)]
        }
      })
    }
    return categoryName
  }
  return categoryName
}

// breadCrumb for First match with json of breadcrumb-sort
const getChildrenCategoryList = (children, product, key) => {
  let matchArrayCategories = null
  let FirstMatchArray = children.filter((element) => {
    return product?.categories?.some((obj) => obj === element.id)
  })
  for (let i = 0; i < breadCrumbCategory?.length; i++) {
    for (let j = 0; j < FirstMatchArray?.length; j++) {
      if (breadCrumbCategory[i]?.[key] === FirstMatchArray[j].id) {
        matchArrayCategories = FirstMatchArray[j]
        break
      }
    }
    if (matchArrayCategories) {
      break
    }
  }
  return matchArrayCategories
}

export const getProductCategory = (product) => {
  const categoryField = product?.metafields?.filter((item) => item?.key === 'sitka_category')

  if (!categoryField?.[0]?.value) return null

  try {
    const category = JSON.parse(categoryField?.[0]?.value)
    return category.en
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getCurrentVariant = ({ selectedOptions, variants }) => {
  return (
    variants?.filter((variant) =>
      variant.option_values.every(
        (option) =>
          selectedOptions[option.option_display_name] &&
          selectedOptions[option.option_display_name].label === option.label
      )
    )?.[0] || selectedOptions
  )
}

export const checkIfImageExists = (url, callback) => {
  url = url.includes('/') ? url.replace('/', '-') : url
  url = url.includes(' ') ? url.replace(' ', '-') : url
  const filteresSwatches = swatches.filter((swatch) => {
    let removeExt = swatch.split('.png')
    return removeExt[0].toLowerCase() == url
  })

  if (filteresSwatches && filteresSwatches.length > 0) {
    callback(filteresSwatches[0])
  } else {
    callback(false)
  }
}

export const buildSwatchesUrl = (optionValue) => {
  let url = '/images/photo-empty.png'
  if (optionValue && optionValue.label) {
    try {
      let formatedLabel = optionValue.label.toLowerCase().split(' ').join('-')
      checkIfImageExists(formatedLabel, (exist) => {
        if (exist) {
          url = '/images/swatch/' + exist
        }
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  return url
}

export const getCurrentVariantFromSelectedOptions = ({
  selectedOptions,
  variants,
  allowUnavailable = false,
}) => {
  const availableVariants = allowUnavailable ? variants : getAvailableVariants(variants)
  return (
    availableVariants?.filter((variant) =>
      variant.option_values.every(
        (option) => selectedOptions[option?.option_display_name]?.label === option.label
      )
    )?.[0] || null
  )
}

export const isProductOptionAvailable = ({ selectedOptions, variants }) => {
  return getCurrentVariantFromSelectedOptions({ selectedOptions, variants }) !== null
}

export const getVariantFeaturesAndSpecs = ({ variant }) => {
  try {
    const productFeatures = variant.metafields.find((meta) => meta.key === 'product_features')
    const line = JSON.parse(productFeatures.value).en.split('\n').join('<br/>')
    let lines = line?.split('<br/>')

    if (lines && lines.length > 0) {
      lines = lines.map((line) => `<div class='feature_item'>${line}</div>`).join('')
      return lines
    }

    return JSON.parse(productFeatures.value).en.split('\n').join('<br/>')
  } catch (e) {
    return null
  }
}

export const getBadgeSrc = (badge) => {
  if (badge?.value) {
    const badgeData = badge?.value?.split(',')

    if (badgeData.includes('PRIMALOFT®')) {
      return '/images/primaloft.png'
    }
    if (badgeData.includes('INSECT SHIELD®')) {
      return '/images/insect-shield.png'
    }
    if (badgeData.includes('POLYGIENE®')) {
      return '/images/polygiene.png'
    }
    if (badgeData.includes('WINDSTOPPER®️ by GORE Labs')) {
      return '/images/windstopper-by-gore-labs.png'
    }
    if (badgeData.includes('Aerolite Collection')) {
      return '/images/aerolite-collection.png'
    }
    if (badgeData.includes('POLARTEC®')) {
      return '/images/polartec.png'
    }
    if (badgeData.includes('GORE-TEX®')) {
      return '/images/product/bage.png'
    }
  }
}

export const applyWarehouseInventory = (variants, warehouseInventory) => {
  if (variants && variants.length > 0 && warehouseInventory) {
    let newVariants = variants.map((variant) => {
      let inventory = warehouseInventory.find((inventory) => inventory?.variant_sku == variant?.sku)

      let normalInventory = 0
      let proInventory = 0
      let combinedInventory = 0

      if (inventory) {
        normalInventory = parseInt(inventory?.standard_quantity || 0)
        proInventory = parseInt(inventory?.pro_quantity || 0)
        combinedInventory =
          parseInt(inventory?.standard_quantity || 0) + parseInt(inventory?.pro_quantity || 0)
      }

      return {
        ...variant,
        inventory_level: normalInventory,
        pro_inventory_level: proInventory,
        combined_inventory_level: combinedInventory,
      }
    })

    return newVariants
  }

  return []
}

export const getFallbackVariant = (product, warehouseInventory, variantId = null) => {
  const color = product?.options?.filter((option) => option.display_name === 'Color')?.[0]

  const filteredVariants = applyWarehouseInventory(
    product?.variants?.filter((variant) => !variant.purchasing_disabled),
    warehouseInventory
  )
  if (variantId) {
    const variant = filteredVariants.find((item) => item.id == variantId)
    if (variant) {
      return variant
    }
  }
  const availableVariants = filteredVariants?.filter((variant) => {
    let colorOption = color?.option_values?.[0]
    let variantColorOption = variant?.option_values?.find(
      (optionValue) => optionValue?.option_display_name == 'Color'
    )

    return colorOption?.label == variantColorOption?.label
  })

  if (availableVariants && availableVariants.length > 0) {
    return availableVariants[0]
  }

  return filteredVariants?.[0] || null
}

export const buildCurrentVariantFromWarehouse = (
  variants,
  warehouseInventory,
  pro = false,
  variantId = null
) => {
  if (warehouseInventory?.length > 0) {
    let newVariants = applyWarehouseInventory(variants, warehouseInventory)
    if (variantId) {
      const variant = newVariants.find((item) => item.id == variantId)
      if (variant) {
        return variant
      }
    }
    let currentVariant = pro
      ? newVariants.filter((variant) => variant.combined_inventory_level > 0)?.[0]
      : getAvailableVariants(newVariants)?.[0]

    return currentVariant || null
  }

  return null
}

export const isInventoryAvailable = (
  selectedOptions,
  variants,
  warehouseInventory,
  variant = null,
  pro = false
) => {
  const newVariant =
    variant ||
    getCurrentVariantFromSelectedOptions({ selectedOptions, variants, allowUnavailable: true })

  if (newVariant && newVariant?.sku && warehouseInventory?.length > 0) {
    const inventory = warehouseInventory.find(
      (inventory) => newVariant?.sku == inventory?.variant_sku
    )

    if (inventory) {
      return pro
        ? parseInt(inventory?.pro_quantity) > 0 || parseInt(inventory?.standard_quantity) > 0
        : parseInt(inventory?.standard_quantity) > 0
    }
  }

  return false
}

export const addLifestyleImages = (lifestyleImages) => {
  // SITKHYPER-136 add large image for the lifestyle image quality
  return {
    entryTitle: 'PDP static lifestyle image',
    layout: '2 up',
    sectionType: 'Image Gallery 2 Up',
    images: [
      {
        url:
          lifestyleImages[0]?.url_zoom?.replace(/(^\w+:|^)\/\//, '') ||
          lifestyleImages[0]?.url_standard?.replace(/(^\w+:|^)\/\//, ''),
        title: 'Lifestyle image left',
      },
      {
        url:
          lifestyleImages[1]?.url_zoom?.replace(/(^\w+:|^)\/\//, '') ||
          lifestyleImages[1]?.url_standard?.replace(/(^\w+:|^)\/\//, ''),
        title: 'Lifestyle image right',
      },
    ],
    sys: {
      id: 'pdp-static-life-style-image',
      contentType: { sys: { id: 'moduleImageGalleryUp' } },
    },
  }
}

export const checkProductWader = (product) => {
  let excludeProDiscount = false
  let productType = ''

  for (let i = 0; i < product?.length; i++) {
    if (product[i].name === 'exclude_pro_discount') {
      excludeProDiscount = product[i]?.value?.toLowerCase()
    }
    if (product[i].name === 'product_type') {
      productType = product[i]?.value?.toLowerCase()
    }
  }
  if (productType === 'wader' || excludeProDiscount === 'true') {
    return true
  } else {
    return false
  }
}

export const checkProductWaderName = (productName) => {
  if (productName?.toLowerCase() === 'wader') {
    return true
  } else {
    return false
  }
}

export const checkProductExcludeDiscount = (productExclude) => {
  if (productExclude?.toLowerCase() === 'true') {
    return true
  } else {
    return false
  }
}

export const getProductReviewCount = (sku) => {
  return reviewsCount.find((item) => item.id == sku)?.score || null
}

export const getSaleModifies = (data, wishlist, isProUser, isCart) => {
  let checkIsSale = false
  if (wishlist) {
    checkIsSale =
      data?.selected_varian?.sale_price && data?.selected_varian?.sale_price !== 0
        ? +data?.selected_varian?.sale_price < +data?.selected_varian?.price
        : isProUser
        ? +data?.selected_varian?.originalPrice < +data?.selected_varian?.price
        : false
  } else {
    if (isCart) {
      checkIsSale =
        data?.variant?.sale_price && data?.variant?.sale_price !== 0
          ? +data?.variant?.sale_price < +(+data?.variant?.price)
          : false
    } else {
      checkIsSale =
        data?.salePrice && data?.salePrice !== 0 ? +data?.salePrice < +(+data?.price) : false
    }
  }

  let option_id_sale = null
  let option_value_sale = null
  data?.modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'sale') {
      if (checkIsSale || isProUser) {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'Yes') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      } else {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'No') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      }
    }
  })
  return { option_id_sale, option_value_sale }
}

export const getWarehouseModifies = (data, isProUser) => {
  // for warehouse modifiers
  let option_value_warehouse = null
  let option_id_warehouse = null
  // let isProUser = false
  // modifiers for Warehouse
  data?.modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'warehouse') {
      obj?.option_values?.map((opt) => {
        if (isProUser && opt?.label === '03') {
          option_id_warehouse = opt?.option_id
          option_value_warehouse = opt?.id
          return
        } else if (opt?.label === '02') {
          option_id_warehouse = opt?.option_id
          option_value_warehouse = opt?.id
          return
        }
      })
    }
  })
  return { option_id_warehouse, option_value_warehouse }
}
