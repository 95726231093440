import Image from 'next/image'
import styleConfig from '@/styles/style-config'

const deviceSizeMap = ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].reduce(
  (map, sizeKey) => ({
    ...map,
    [sizeKey]: Number(styleConfig.theme.screens[sizeKey]?.replace('px', '')),
  }),
  {}
)

const defaultSizes = [
  `(min-width: ${deviceSizeMap['2xl']}px) ${deviceSizeMap['2xl']}px`,
  `(min-width: ${deviceSizeMap.xl}px) ${deviceSizeMap.xl}px`,
  `(min-width: ${deviceSizeMap.lg}px) ${deviceSizeMap.lg}px`,
  `${deviceSizeMap.md}px`,
].join(',')

const handleContenfulImage = ({ src = '', width, quality }) => {
  const queryParams = ['fm=webp']
  if (width) {
    queryParams.push(`w=${width}`)
  }
  if (quality) {
    queryParams.push(`q=${quality}`)
  }
  return queryParams.length ? `${src}?${queryParams.join('&')}` : src
}

const handleBigCommerceImage = ({ src = '', width }) => {
  return `${
    process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
  }/_next/image?url=${src}&w=1200&h=1200&q=90`
}

const loader = (loadedProps) => {
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContenfulImage(loadedProps)
  if (src?.includes('bigcommerce')) return handleBigCommerceImage(loadedProps)
  return src
}

export const NextImage = ({
  src,
  srcMap,
  alt = '',
  height,
  width,
  className,
  style = {},
  objectFit = 'contain',
  objectPosition,
  layout = 'fill',
  quality = 75,
  priority = false,
  unoptimized = false,
  useSrc = false,
  placeholder,
  blurDataURL,
}) => {
  if (process.env.NEXT_PUBLIC_IS_VERCEL == 'true') {
    return (
      <Image
        unoptimized={unoptimized}
        className={className}
        src={src}
        style={style}
        sizes={layout === 'fill' || layout === 'responsive' ? defaultSizes : null}
        alt={alt}
        quality={quality}
        priority={priority}
        loading={priority ? 'eager' : 'lazy'}
        layout={layout}
        height={height}
        width={width}
        objectFit={objectFit}
        objectPosition={objectPosition}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
      />
    )
  } else {
    return (
      <Image
        unoptimized={unoptimized || !!src?.includes('bigcommerce')}
        className={className}
        loader={loader}
        src={src}
        sizes={layout === 'fill' || layout === 'responsive' ? defaultSizes : null}
        alt={alt}
        quality={quality}
        priority={priority}
        loading={priority ? 'eager' : 'lazy'}
        layout={layout}
        height={height}
        width={width}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    )
  }
}
