import Cookies from 'js-cookie'
import { server } from '@/config/server'
import { authCreate } from '@/lib/client/authCustomerClient'
import { create } from '@/lib/client/customerClient'

export const authClient = authCreate()
export const client = create()

export default authClient

let publicURL = process.env.NEXT_PUBLIC_BASE_URL
publicURL = publicURL.replace('https://', '').replace('/', '')

export const CustomerService = {
  // Commented this is not used
  // async login({ email, password }) {
  //   if (!email || !password) {
  //     throw new Error('Please provide customer email and password')
  //   }

  //   const params = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email, password }),
  //   }

  //   const response = await fetch(`${server}/api/bigcommerce/customers/login`, params)
  //   const data = await response.json()

  //   if (data && data.success) {
  //     data.customer_token &&
  //       Cookies.set('customer_token', data.customer_token, {
  //         httpOnly: false,
  //         expires: new Date(new Date().setDate(new Date().getDate() + 7)),
  //       })
  //     console.log('subdomain-----', process.env.NEXT_PUBLIC_SUB_DOMAIN)
  //     Cookies.set('sitka_customer_token', data.customer_token, {
  //       domain: process.env.NEXT_PUBLIC_SUB_DOMAIN,
  //       secure: false,
  //       expires: new Date(new Date().setDate(new Date().getDate() + 7)),
  //     })
  //   }
  //   return data
  // },

  // Commented this is not used
  // async loginToCheckout(customerId, checkoutUrl) {
  //   if (!customerId || !checkoutUrl) {
  //     throw new Error('Please provide customer id and checkout url')
  //   }

  //   const params = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ customerId, checkoutUrl }),
  //   }

  //   const response = await fetch(`${server}/api/bigcommerce/customers/login`, params)
  //   const data = await response.json()

  //   if (data && data.success) {
  //     return data
  //   } else {
  //     return null
  //   }
  // },

  // Create Customer
  async createCustomer(formData) {
    try {
      const response = await client.post('customers/create', { data: formData })
      return response.data
    } catch (error) {
      console.log(`Error in create customer API call`, error)
      throw error.response
    }
  },

  // Get Customer Details
  async getCustomerDetails(body) {
    const customerToken = Cookies.get('customer_token')
    if (customerToken) {
      try {
        const response = await authClient.post('/customers/details', body)
        return response.data
      } catch (error) {
        console.log(`Error in new get customer API call`, error)
      }
    }
  },

  // Update Customer Detail
  async updateCustomer(customer) {
    try {
      const response = await authClient.post('/customers/update', customer)
      return response.data
    } catch (error) {
      console.log(`Error in new get customer API call`, error)
      throw error.response
    }
  },

  // Update Customer Address
  async updateCustomerAddress(address) {
    try {
      const response = await authClient.post('/customers/address-update', address)
      return response.data
    } catch (error) {
      console.log(`Error in update customer address API call`, error)
      throw error.response
    }
  },

  // Add Customer Address
  async addCustomerAddress(address) {
    try {
      const response = await authClient.post('/customers/address-add', address)
      return response.data
    } catch (error) {
      console.log(`Error in add new customer address API call`, error)
      throw error.response
    }
  },

  // Get Customer Address
  async getCustomerAddresses(customerId) {
    try {
      const response = await authClient.post('/customers/addresses', {
        'customer_id:in': customerId,
        include: 'formfields',
      })
      return response.data
    } catch (error) {
      console.log(`Error in get customer address API call`, error)
      throw error.response
    }
  },

  // Get Customer Orders
  async getCustomerOrders(params) {
    const customerToken = Cookies.get('customer_token')
    try {
      const response = await authClient.get(`/orders/history?` + new URLSearchParams(params))
      return response.data
    } catch (error) {
      console.log(`Error in get customer orders API call`, error)
      throw error.response
    }
  },

  // Get Customer order Detail
  async getCustomerOrder(orderId) {
    try {
      const response = await authClient.post('/customers/order', {
        order_id: orderId,
      })
      return response.data
    } catch (error) {
      console.log(`Error in get Customer order detail API call`, error)
      throw error.response
    }
  },

  // async getOrderProducts(orderId) {
  //   const customerToken = Cookies.get('customer_token')

  //   const response = await fetch(`${server}/api/bigcommerce/customers/order-products`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${customerToken}`,
  //     },
  //     body: JSON.stringify({
  //       order_id: orderId,
  //     }),
  //   })
  //   const data = await response.json()
  //   return data
  // },

  // Get Order product history
  async getOrderProductsHistory(params) {
    try {
      const response = await authClient.post('/orders/products-history', params)
      return response.data
    } catch (error) {
      console.log(`Error in get order product history detail API call`, error)
      throw error.response
    }
  },

  // LogOut customer and clear cookies
  async logoutCustomer() {
    try {
      const response = await authClient.post('/customers/logout', {})
      if (response?.data && response?.data?.success) {
        let domain = process.env.NEXT_PUBLIC_BASE_URL || process.env.NEXT_PUBLIC_API_BASE_URL
        domain = domain?.replace('/', '')
        const arrayOfDomain = domain?.split('.')
        const lastDomain = arrayOfDomain?.slice(-3)
        const mainDomain = lastDomain?.join('.')

        // Remove cookies from current domain/pages
        Cookies.remove()
        Cookies.remove('sitka_customer_token', { domain: process.env.NEXT_PUBLIC_SUB_DOMAIN })
        // logout for product checkout page and sitka site
        for (const value of [
          'customer_token',
          'cart',
          'wishlist',
          'newsletter-modal-close',
          'SHOP_TOKEN',
        ]) {
          // Dot domain
          Cookies.remove(value, {
            domain: `.${mainDomain}`,
          })

          // Current domain
          Cookies.remove(value)
        }
        return response.data
      }
    } catch (error) {
      console.log(`Error in getting approval user list for promo order API call`, error)
      throw error.response
    }
  },

  // Create Customer Wishlist
  async createCustomerWishlist(params) {
    let currentDate = new Date().toLocaleDateString()
    const wishlistName = `${
      params.items?.find((item) => item.product_category)?.product_category || 'No category'
    } - ${currentDate}`

    try {
      const response = await authClient.post('/customers/wishlists/create', {
        name: params.name || wishlistName,
        is_public: false,
        customer_id: params.customerId,
        items: params.items,
      })
      return response.data
    } catch (error) {
      console.log(`Error in creating customer wishlist API call`, error)
      throw error.response
    }
  },

  // Update Customer Wishlist
  async updateCustomerWishlist(params) {
    try {
      const response = await authClient.post('/customers/wishlists/update', {
        wishlist_id: params.wishlist_id,
        items: params.items,
      })
      return response.data
    } catch (error) {
      console.log(`Error in adding new product in customer wishlist detail API call`, error)
      throw error.response
    }
  },

  // Commented this is not used
  // async customerWishlist(params) {
  //   const customerToken = Cookies.get('customer_token')

  //   if (Cookies.get('wishlist')) {
  //     //update wishlist
  //     const response = await fetch(`${server}/api/bigcommerce/customers/wishlists/update`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${customerToken}`,
  //       },
  //       body: JSON.stringify({
  //         wishlist_id: Cookies.get('wishlist'),
  //         items: params.items,
  //       }),
  //     })
  //     const data = await response.json()
  //     return data
  //   } else {
  //     //create wishlist
  //     const response = await fetch(`${server}/api/bigcommerce/customers/wishlists/create`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${customerToken}`,
  //       },
  //       body: JSON.stringify({
  //         name: params.name || 'Default',
  //         is_public: false,
  //         customer_id: params.customerId,
  //         items: params.items,
  //       }),
  //     })
  //     const data = await response.json()

  //     if (data.data.id) {
  //       Cookies.set('wishlist', data.data.id)
  //     }

  //     return data
  //   }
  // },

  // Get Customer wishlist
  async getCustomerAllWishlists(customerId) {
    try {
      const response = await authClient.post('/customers/wishlists/get-all', {
        customer_id: customerId,
      })
      return response.data
    } catch (error) {
      console.log(`Error in get all wishlist list API call`, error)
      throw error.response
    }
  },

  // Delete product from the wishlist
  async deleteCustomerWishlistItem(params) {
    try {
      const response = await authClient.post('/customers/wishlists/delete-item', {
        itemID: params.itemID,
        wishlistID: params.wishlistID,
      })

      if (response.status === 204) {
        return {
          status: 200,
          message: `Wishlist id:${params.itemID} deleted`,
        }
      }
      return response.data
    } catch (error) {
      console.log(`Error in deleting product from wishlist wishlist list API call`, error)
      throw error.response
    }
  },

  // Delete wishlist
  async deleteCustomerWishlist(params) {
    const customerToken = Cookies.get('customer_token')

    try {
      const response = await authClient.post('/customers/wishlists/delete', {
        wishlist_id: params.wishlistId,
      })
      if (response.status == 204) {
        return {
          status: 200,
          message: `Wishlist id:${params.wishlistId} deleted`,
        }
      }
      return response.data
    } catch (error) {
      console.log(`Error in deleting wishlist list API call`, error)
      throw error.response
    }
  },

  // Create Checkout Url
  async createCustomerChekoutUrl(payload) {
    //const customerToken = Cookies.get('customer_token')
    try {
      const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL + '/api'
      const response = await authClient.post(
        `/cart-checkout`,
        {
          ...payload,
          generate_checkout_url: true,
        },
        {
          baseURL: baseUrl,
        }
      )
      return response.data
    } catch (error) {
      console.log(`Error in creating customer checkout url list API call`, error)
      throw error.response
    }
  },

  // Get Customer Wishlist by id
  async getCustomerWishlistById(wishListId) {
    try {
      const response = await authClient.post('/customers/wishlists/get-by-id', {
        wishlist_id: wishListId,
      })
      return response.data
    } catch (error) {
      console.log(`Error in getting customer wishlist detail API call`, error)
      throw error.response
    }
  },

  // Commented this is not used
  // async getProductsWishlist(productsID) {
  //   try {
  //     const response = await authClient.post('/products/get-by-ids', {
  //       ['id:in']: productsID,
  //     })
  //     console.log('getProductsWishlist', response.data)
  //     return response.data
  //   } catch (error) {
  //     console.log(`Error in getting customer wishlist detail API call`, error)
  //     throw error.response.response
  //   }

  //   const response = await fetch(`${server}/api/bigcommerce/products/get-by-ids`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${customerToken}`,
  //     },
  //     body: JSON.stringify({
  //       ['id:in']: productsID,
  //     }),
  //   })

  //   const data = await response.json()
  //   return data
  // },

  // Commented this is not used
  // async getResetPasswordLink(email) {
  //   const customerToken = Cookies.get('customer_token')

  //   const response = await fetch(`${server}/api/bigcommerce/customers/reset-password`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${customerToken}`,
  //     },
  //     body: JSON.stringify({
  //       email: email,
  //     }),
  //   })
  //   if (response.status != 200) {
  //     return { error: response.status, expiry: null }
  //   } else {
  //     const data = await response.json()

  //     return data
  //   }
  // },

  async newsLetterSubscription(props) {
    try {
      const response = await client.post('/customers/subscription/create', params)
      return response.data
    } catch (error) {
      console.log(`Error in add new letter subscription detail API call`, error)
      throw error.response
    }
  },

  // Get Customer Flag
  async getCustomerFlags() {
    try {
      const response = await authClient.post('/customers/flags', {})
      return response.data
    } catch (error) {
      console.log(`Error in getting customer flag API call`, error)
      throw error.response
    }
  },

  // Commented this is not used
  // async outOfStockProductSubscription(params) {
  //   const response = await fetch(
  //     `https://a.klaviyo.com/api/v2/list/SyGmSz/subscribe?api_key=pk_d2d69f6ebe3e487a686ba1239ff4050ef8`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //       body: {
  //         profiles: {
  //           email: 'test@gmail.com',
  //         },
  //       },
  //     }
  //   )
  //   const data = await response
  //   return data
  // },

  async getReasonForRequest() {
    const response = await fetch(`${server}/api/reason-for-request`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  },

  // Get Approval user list for promo order
  async getApprovalUserList(limit, page) {
    try {
      const response = await authClient.get(
        `/users/promotion-order-approval?limit=${limit}&page=${page}`
      )
      return response.data
    } catch (error) {
      console.log(`Error in getting approval user list for promo order API call`, error)
      throw error.response
    }
  },

  // Complete promo order request
  async completePRomoOrderRequest(params) {
    try {
      const response = await authClient.post('/order/promotion', params)
      return response
    } catch (error) {
      console.log(`Error in promo order request API call`, error)
      throw error.response
    }
  },

  // Get customer group detail
  async getCustomerGroup(groupId) {
    try {
      const response = await client.post('customers/group/get', { groupId })
      return response.data
    } catch (error) {
      console.log(`Error in getting approval user list for promo order API call`, error)
      throw error.response
    }
  },
}
