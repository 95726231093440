export const trimSlashes = (str) => {
  return str.replace(/^\/|\/$/g, '')
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}
