import axios from 'axios'
import getConfig from 'next/config'

const { NEXT_PUBLIC_API_URL } = getConfig().publicRuntimeConfig

let client

export function adminCreate(options = {}) {
  if (client) {
    return client
  }
  client = axios.create({
    baseURL: `${NEXT_PUBLIC_API_URL}/adminapi`,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  client.interceptors.request.use(async (config) => {
    return config
  })

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )
  return client
}
