import badges from '@/lib/contentful/data/product-badges.json'

export const getCustomBadgeData = async (badgeId) => {
  try {
    const data = badges.find((badge) => badge.badgeId === badgeId)
    return data
  } catch (error) {
    console.log('getCustomBadgeData error', error)
    return null
  }
}
