import axios from 'axios'
import Router from 'next/router'
import Cookies from 'js-cookie'
// import { store } from '@/redux/store/configureStore'
// import { onUnauthorizedToken } from '@/redux/actions/userAction'

import { server } from '@/config/server'

let client

export function create(options = {}, isTrue) {
  if (client) {
    return client
  }
  client = axios.create({
    baseURL: `${server}/api/bigcommerce`,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  client.interceptors.request.use(async (config) => {
    return config
  })

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )
  return client
}
