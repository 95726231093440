import axios from 'axios'
import Cookies from 'js-cookie'
import getConfig from 'next/config'
import { store } from '@/redux/store/configureStore'
import { onUnauthorizedToken } from '@/redux/actions/userAction'

const { NEXT_PUBLIC_API_URL } = getConfig().publicRuntimeConfig

let client

export function authCreate(options = {}) {
  if (client) {
    return client
  }
  client = axios.create({
    baseURL: `${NEXT_PUBLIC_API_URL}/api`,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  client.interceptors.request.use(async (config) => {
    let customerToken = Cookies.get('customer_token')

    config.headers.Authorization = `Bearer ${customerToken}`

    return config
  })

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error && error?.response && error?.response?.status === 401) {
        // Router.push('?logout=true')
        store.dispatch(onUnauthorizedToken())
      }
      return Promise.reject(error)
    }
  )
  return client
}
